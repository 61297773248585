import { useState } from "react"
import Dropdown from "../common/Dropdown"
import Textbox from "../common/Textbox"
import { LANGUAGES } from "../../library/constant"
import { __t } from "../../library/textHelper"

export interface SeoProp{
    onChange: Function
    form: Array<{title: string, description: string, keywords?: string, language: string}>
}

export default function Seo(props: SeoProp){
    const [language, setLanguage] = useState("")
    
    const onChange = (e) => {
        if(e.currentTarget.name === "language"){
            setLanguage(e.currentTarget.value)
        }else {
            props.onChange({currentTarget: {name: `seo_${language}_${e.currentTarget.name}`, value: e.currentTarget.value}})
        }
    }
    return (
        <div className="flex flex-col gap-4">
            <Dropdown name="language" label="language" onChange={onChange} value={language} list={LANGUAGES.map(x => ({title: x.title, value: x.code}))} />
            <Textbox className="w-full" type="text" name="title" label="title" value={props.form.find(x => x.language === language)?.title || ""} onChange={onChange} />
            <Textbox className="w-full" type="text" name="keywords" label="keyword" value={props.form.find(x => x.language === language)?.keywords || ""} onChange={onChange} />
            <Textbox type="textarea" name="description" label="description" onChange={(e) => onChange({currentTarget: {name: `description`, value: e.target.value}})} value={props.form.find(x => x.language === language)?.description || ""} />
        </div>
    )
}