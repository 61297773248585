import { Link } from "react-router-dom";
import Datatable, { Datasource } from "../../components/container/DataTable";
import Layout from "../../layouts";
import { DELETE_CATEGORY, FETCH_CATEGORIES } from "../../graphql";
import RemoveDialog from "../../components/common/RemoveDialog";
import { __t } from "../../library/textHelper";

const DATASOURCE: Datasource = {
    columns: [
        { title: __t("parent"), field: "parent", template: (row) => (<span>{row?.parent?.title}</span>) },
        { title: __t("title"), field: "title" },
        { title: __t("type"), field: "type" }
    ],
    title: (row, key) => row["title"],
    subTitle: (row, key) => row["type"],
    actions: [
        (row, key) => (<Link key={key} to={`${row._id}`}><i className="ico-pencil-square w-5 h-5 bg-black">&nbsp;</i></Link>),
        (row, key) => (<RemoveDialog key={key} id={row._id} reload={true} query={DELETE_CATEGORY} />),
    ]
}

export default function Category(){
    return (
        <Layout>
            <Datatable query={FETCH_CATEGORIES} dataKey="GetCategories" newRoute="new" datasource={DATASOURCE} />
        </Layout>
    )
}