import { useContext, useState } from 'react'
import LogoImage from '../../assets/logo.svg'
import Button from '../../components/common/Button'
import Textbox from '../../components/common/Textbox'
import { mutation, query } from '../../library/apolloClient'
import { FETCH_SETTINGS, LOGIN } from '../../graphql'
import { Link } from 'react-router-dom'
import { ThemeContext } from '../../context/theme'
import Title from '../../components/common/Title'
import { validation } from '../../library/textHelper'

const rules:Array<{field: string, message: string, validate: (val: any) => boolean}>  = [
    {
        field: "email",
        message: "Please enter valid email address",
        validate: (val) => {
            return String(val).match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/gim)?.length > 0
        }
    },
    {
        field: "password", 
        message: "Your password should at least 8 characters with special characters and numbers included",
        validate: (val) => {
            return String(val).match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!^%*?&]{8,128}$/gim)?.length > 0
        }
    }
]

export default function Login(){
    const [theme] = useContext(ThemeContext)
    const [form, setForm] = useState({ email: "", password: "" })
    const [errors, setErrors] = useState<Array<{field: string, message: string}>>([])

    const onSubmit = (e) => {
        e.preventDefault();
        const _errors = validation(form, rules)
        setErrors(_errors);
        if(_errors.length <= 0){
            theme.setLoading(true);
            mutation(LOGIN, {authInput: form}, {})
            .then(({data}) => {
                theme.setLoading(false);
                window.localStorage.setItem("profile", JSON.stringify({ token: data["loginUser"].token, ...data["loginUser"].profile }))
                query(FETCH_SETTINGS, {}, {}).then(({data}) => {
                    const _lang = data["GetSettings"]["customSettings"]?.find(x => x.key == "language")?.value || "en"
                    window.localStorage.setItem("language", _lang)
                    window.location.href = "/"
                }).catch(err => {
                    window.localStorage.setItem("language", data["loginUser"].profile?.UserDetails.find(x => x.key == "language")?.value || "en")
                    window.location.href = "/"
                })
            })
            .catch(err => {
                theme.setLoading(false);
                theme.setAlert({...theme.alert, message: "Your email address / password invalid"})
            })
        }
    }

    const onChange = (e) => {
        const _form = {...form};
        _form[e.target.name] = e.target.value;
        setForm(_form);
    }

    return (
        <div className='container flex justify-center items-start md:items-center mx-auto my-5 md:my-auto h-screen'>
            <div className='flex flex-col gap-4'>
                <img src={LogoImage} alt="Logo" className='w-96' />
                <form className='flex flex-col gap-4' onSubmit={onSubmit}>
                    <Textbox type='text' name='email' label='Email Address' onChange={onChange} error={errors.find(x => x.field == "email")?.message} value={form.email}  />
                    <Textbox type='password' name='password' label='Password' onChange={onChange} error={errors.find(x => x.field == "password")?.message} value={form.password}  />
                    <Button title="Login" className="w-full" onClick={() => {}} />
                    <div className='grid grid-cols-2 w-full'>
                        <Link to="#" className='underline'>Forget password?</Link>
                        <Title>haven't account? <Link to={"/signup"} className='underline'>Signup</Link></Title>
                    </div>
                </form>
            </div>
        </div>
    )
}