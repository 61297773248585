import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import Dropdown from "./Dropdown";
import TabView from "../container/TabView";
import { Template } from "../../library/template";
import htmlParser from 'html-react-parser';

export interface QuilEditorProp {
    name: string
    isContent?: boolean
    value: string
    tags?: boolean
    language?: string,
    onChange: Function
}

const modules = {
    toolbar: {
        "container": [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            ['link', 'video', 'formula', 'image'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'align': [] }],
            ['clean']                                         // remove formatting button
        ],
        "handlers": {
            image: function () {
                const tooltip = this.quill.theme.tooltip;
                const originalSave = tooltip.save;
                const originalHide = tooltip.hide;
                tooltip.save = function () {
                    const range = this.quill.getSelection(true);
                    const value = this.textbox.value;
                    if (value) {
                        this.quill.insertEmbed(range.index, 'image', value, 'user');
                    }
                };
                // Called on hide and save.
                tooltip.hide = function () {
                    tooltip.save = originalSave;
                    tooltip.hide = originalHide;
                    tooltip.hide();
                };
                tooltip.edit('image');
                tooltip.textbox.placeholder = 'Embed URL';
            }
        },
    }
}

const TAGS: Array<{ title: string, value: string }> = [
    { title: "Heading", value: "{{head | }}" },
    { title: "Sub Heading", value: "{{subhead | }}" },
    { title: "Dictionary", value: "{{dictionary | }}" },
    { title: "Paragraph", value: "{{p | }}" },
    { title: "Image", value: "{{image | }}" },
    { title: "Video", value: "{{video | }}" },
    { title: "List", value: "{{list | }}" },
    { title: "Number List", value: "{{number-list | }}" },
    { title: "Foot Note", value: "{{foot | | }}" },
    { title: "Translator", value: "{{translator | | }}" },
    { title: "Link", value: "{{link | }}" },
    { title: "quote-quranic-verse", value: "{{quote | quranic-verse | }}" },
    { title: "quote-hadith-verse", value: "{{quote | hadith-verse | }}" },
    { title: "quote-translation", value: "{{quote | translation | }}" },
    { title: "quote-arabic", value: "{{quote | ar-quote | }}" },
    { title: "quote-urdu", value: "{{quote | ur-quote | }}" },
    { title: "quote-english", value: "{{quote | en-quote | }}" },
    { title: "Ayah Number", value: "{{ayah-number | }}" },
    { title: "symbol", value: "{{symbol | en | 1 }}" },
    { title: 'my font', value: "{{font | jameelnoorinastaleeq | }}" },
    { title: "Related", value: "{{ related |  }}" }
];

const SUBTAGS: Array<{ title: string, value: string }> = [
    { title: "Select Sub Tag", value: "" },
    { title: "Heading", value: "[head, ]" },
    { title: "Sub Heading", value: "[subhead, ]" },
    { title: "Paragraph", value: "[p, ]" },
    { title: "Image", value: "[image, ]" },
    { title: "Video", value: "[video, ]" },
    { title: "List", value: "[list, ]" },
    { title: "Number List", value: "[number-list, ]" },
    { title: "Foot Note", value: "[foot, ]" },
    { title: "Link", value: "[link, ]" },
    { title: "quote-quranic-verse", value: "[quote, quranic-verse, ]" },
    { title: "quote-hadith-verse", value: "[quote, hadith-verse, ]" },
    { title: "quote-translation", value: "[quote, translation, ]" },
    { title: "quote-arabic", value: "[quote, ar-quote,]" },
    { title: "quote-urdu", value: "[quote, ur-quote, ]" },
    { title: "quote-english", value: "[quote, en-quote, ]" },
    { title: "Ayah Number", value: "[ayah-number, ]" },
    { title: "symbol", value: "[symbol, en, ]" },
    { title: 'my font', value: "[font, jameelnoorinastaleeq, ]" }
]

export default function QuilEditor(props: QuilEditorProp) {
    const editor = useRef()
    const [content, setContent] = useState("");

    useEffect(() => {
        const _editor = (editor.current as any)
        if (content.length <= 0 && props.isContent) {
            setContent(props.value)
        }
    }, [props])

    const onTagSelect = (e) => {
        const _editor = (editor.current as any)?.getEditor()
        const range = _editor?.getSelection(true)
        _editor?.insertText(range?.index || 0, e.currentTarget.value)
    }

    const onChange = (e) => {
        setContent(e)
        props.onChange(e)
    }

    return (
        <div className="relative flex flex-col items-center space-y-2">
            <TabView isAlive={true} headerStyle="sticky left-0 top-20 z-10" className="bg-white" tabs={[
                {
                    title: "Editor",
                    content: (
                        <>
                            {
                                props.tags && (
                                    <div className="flex w-full gap-4 sticky top-40 z-10 mb-5">
                                        <Dropdown name="tags" label="tags" list={TAGS} onChange={onTagSelect} />
                                        <Dropdown name="subTags" label="sub tags" list={SUBTAGS} onChange={onTagSelect} />
                                    </div>
                                )
                            }
                            <ReactQuill ref={editor} scrollingContainer={"html"} className={`flex flex-col lang-${props.language || "en"} bg-white`} theme="snow" modules={modules} value={content} onChange={onChange} />
                        </>
                    )
                },
                {
                    title: "Preview",
                    ishide: !props.tags,
                    content: (
                        <div className="container mx-auto">
                            <div className={`lang-${props.language || "en"} wrap bg-white`}>{ htmlParser(new Template().render(content)) }</div>
                        </div>
                    )
                }
            ].filter(x => x.ishide != true)} />
        </div>
    )
}