import { __t } from "../../library/textHelper";

export interface DropdownProp{
    label: string;
    name: string;
    value?: any;
    list: Array<{title: string, value: string}>
    onChange: Function;
    className?: string
    itemClassName?: string
    error?: string
}

export default function Dropdown(props: DropdownProp) {
    return (
        <div className={`flex flex-col relative w-full ${props.className}`}>
            <select name={props.name} value={props.value || ""} onChange={(e) => props.onChange(e)} className="peer placeholder:text-transparent outline-none p-2 py-3 h-10 w-full border-b-2 border-black bg-white">
                <option value={""}>{__t(`select ${props.label}`)}</option>
                {
                    props.list.map((x, key) => (
                        <option key={key} value={x.value} className={props.itemClassName}>{x.title}</option>
                    ))
                }
            </select>
            <label className="absolute left-0 top-[20%] ml-1 -translate-y-4 px-1 bg-transparent text-sm duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-500 peer-focus:ml-1 peer-focus:-translate-y-4 peer-focus:px-1 peer-focus:text-sm">{__t(props.label)}</label>
            {
                props.error && (
                    <label className="flex text-sm text-red-600">{props.error}</label>
                )
            }
        </div>
    )
}