
import { useContext, useEffect } from "react";
import { ThemeContext } from "../../context/theme";

export default function Alert(){
    const [theme] = useContext(ThemeContext);
    useEffect(() => {
        if(theme.alert.message != ""){
            setTimeout(() => theme.setAlert({...theme.alert, message: ""}), 5000)
        }

    }, [theme.alert])
    return theme.alert.message && (
        <div className="sticky w-full flex justify-end top-2 px-5" style={{zIndex: 289}}>
            <div className={`absolute flex items-center gap-2 rounded-full p-2 ${theme.alert.color}`}>
                <i className={`ico ${theme.alert.icon} w-5 h-5`}></i><span>{theme.alert.message}</span>
            </div>
        </div>
    )
}