import { ReactNode, useState } from "react"
import Title from "../common/Title";
import Button from "../common/Button";

export interface ExpendableProp{
    key: React.Key;
    title: string
    subTitle?: string;
    action?: Function
    children: ReactNode
    expended?: boolean
    className?: string
}

export default function Expendable(props: ExpendableProp){
    const [isExpend, setExpend] = useState(props.expended)
    const onClick = (e) => {
        e.preventDefault()
        setExpend(!isExpend)
        if(props.action){
            props.action(props.key)
        }        

    }
    return (
        <div key={props.key} className="w-full border-2 border-black p-2 rounded-md">
            <div className="flex w-full">
                <div className="flex flex-col w-full">
                    {props.title && (<Title>{props.title}</Title>)}
                    {props.subTitle && (<Title className="text-slate-600 text-sm">{props.subTitle}</Title>)}
                </div>
                <Button onClick={onClick} title="Check" icoOnly={true} icon={`${isExpend ? 'ico-chevron-up' : 'ico-chevron-down'} bg-white w-5 h-5`} />
            </div>
            {isExpend && props.children}
        </div>
    )
}