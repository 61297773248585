import { useSearchParams } from "react-router-dom";
import { changePage, hasNext, hasPrev } from "../../library/pageHelper";
import Button from "./Button";
import { __t } from "../../library/textHelper";

export interface PaginationProp{
    data: Array<any>,
    limit: number,
    className?: string
}

export default function Pagination(props: PaginationProp){
    const [searchParams, setSearchParams] = useSearchParams()

    const onClick = (e, flag) => {
        e.preventDefault();
        setSearchParams(changePage(flag, searchParams))
    }

    return (
        <div className={props.className}>
            <Button disabled={hasPrev(searchParams)} title={__t("previous")} onClick={(e) => onClick(e, "prev")} />
            <Button disabled={hasNext(props.limit, props.data.length)} title={__t("next")} onClick={(e) => onClick(e, "next")} />
        </div>
    )
}