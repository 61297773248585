import gql from 'graphql-tag'

export const FETCH_PRODUCTS = gql`
query FETCH_PRODUCTS ($query: QueryParams) {
  GetProducts(query: $query) {
    _id
    title
    qty
    uom
    rate
    images
    lang
    home
    brand
    categories {
      _id
      title
    }
  }
}`

export const FETCH_ONE_PRODUCT = gql`
query FETCH_ONE_PRODUCT ($id: String!){
  GetOneProduct(id: $id){
    _id
    title 
    qty
    uom
    rate
    images
    lang
    home
    brand
    discount
    discountType
    productDetail{
      key
      value
    }
    categories{
      _id
      title
      type
    }
  }
}`

export const CREATE_PRODUCT = gql`
mutation CREATE_PRODUCT ($productInput: productInput!){
  CreateProduct(productInput: $productInput){
    _id
  }
}`


export const UPDATE_PRODUCT = gql`
mutation UPDATE_PRODUCT ($id: String!,$productInput: productInput!){
  UpdateProduct(id: $id, productInput: $productInput){
    _id
  }
}
`

export const DELETE_PRODUCT = gql`
mutation DELETE_PRODUCT ($id: String!){
  DeleteProduct(id: $id){
    _id
  }
}
`