import gql from 'graphql-tag'

export const FETCH_CONTENTS = gql`
query FETCH_CONTENTS ($query: QueryParams, $find: contentFilter) {
  GetContents(query: $query, find: $find) {
    _id
    title
    slug
    type
    status
    lang
    categories{
      _id
      title
    }
    style {
        key
        value
    }
  }
}`

export const FETCH_ONE_CONTENT = gql`
query FETCH_ONE_CONTENT ($id: String!){
  GetOneContent(id: $id){
    _id
    title
    slug
    type
    body
    status
    lang
    categories{
      _id
      title
      type
    }
    style {
        key
        value
    }
  }
}`

export const CREATE_CONTENT = gql`
mutation CREATE_CONTENT ($contentInput: contentInput!){
  CreateContent(contentInput: $contentInput){
    _id
  }
}`


export const UPDATE_CONTENT = gql`
mutation UPDATE_CONTENT ($id: String!,$contentInput: contentInput!){
  UpdateContent(id: $id, contentInput: $contentInput){
    _id
  }
}
`

export const DELETE_CONTENT = gql`
mutation DELETE_CONTENT ($id: String!){
  DeleteContent(id: $id){
    _id
  }
}
`