import moment from "moment";
import Dropdown from "../../../components/common/Dropdown";
import Searchtag from "../../../components/common/Searchtag";
import Listview from "../../../components/container/ListView";
import { FETCH_CUSTOMERS } from "../../../graphql";

export interface GeneralProp {
    id: string;
    form: {
        orderNumber: string, status: string,
        customer: any,
        items: Array<any>, address: Array<any>,
        paymentType: string
        createdAt: string
    }
    onChange: Function
}

export default function General(props: GeneralProp) {

    const onChange = (e) => {
        props.onChange(e)
    }
    const onRemoveCustomer = (e) => props.onChange({currentTarget: {name: "customer", value: {}}})

    return (
        <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-col gap-2 w-full">
                <span className="flex justify-end w-full text-sm">{moment(Number(props.form.createdAt)).format("DD-MMMM-Y") }</span>
                <div className="flex gap-2 justify-end w-full">
                    <span className="text-sm">Payment Type:</span>
                    <div className="uppercase text-sm">{props.form.paymentType || "cash"}</div>
                </div>
            </div>
            <div className="flex gap-2">
                <span className="w-full">{props.form.customer.title}</span>

                <Dropdown 
                    label="status" 
                    name="status"
                    className="w-1/2"
                    value={props.form.status} 
                    onChange={onChange}
                    list={[{ title: "Pending", value: "pending" }, { title: "Process", value: "process" }, { title: "Transit", value: "transit" }, { title: "Deliver", value: "deliver" }, { title: "Return", value: "return" }, { title: "Cancel", value: "cancel" }, { title: "Close", value: "close" }]} 
                />
            </div>
            <div className="flex flex-col gap-2">
                <div>Email: {props.form.customer?.email || "No Email"}</div>
                <div>Phone: {props.form.customer?.phone || "No Phone"}</div>

            </div>
            <div className="flex flex-col">
                <span>Address:</span>
                <span>{props.form.address.length > 0 ? `${props.form.address[0].address}` : ""}</span>
                <span>{props.form.address.length > 0 ? `${props.form.address[0].country}, ${props.form.address[0].city}` : ""}</span>
            </div>
            <Listview className="flex flex-col gap-2" data={props.form.items} title={(row) => (
            <div className="flex gap-2">
                <img style={{ width: "80px" }} src={`https://api.metastore.cloud/storage/${row["product"]["images"][0] || ""}`} /> 
                <div className="flex flex-col"> 
                    <span>{row["product"]["title"]}</span>
                    <span>{row["rate"]} x {row["qty"]}</span>
                </div>
            </div>)} actions={[]} />
        </div>
    )
}