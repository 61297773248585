import Layout from "../../layouts"
import { useState } from "react"
import TabView from "../../components/container/TabView"
import General from "./components/general"

export default function MediaEditor() {
    // const params = useParams()
    const [form, setForm] = useState({ title: "", url: "", slug: "" })
    
    // const onData = (data) => {
    //     setForm(data["GetOneMedia"])
    // }

    const onChange = (e) => {
        const _form = { ...form };
        _form[e.currentTarget.name] = e.currentTarget.value;
        setForm({ ..._form });
    }

    return (
        <Layout>
            {/* <Form
                id={params.id}
                query={FETCH_ONE_MEDIA}
                onData={onData}
                mutation={{ create: UPDATE_MEDIA, update: UPDATE_MEDIA }}
                variables={{ mediaInput: form }}
                routeBack="/media"
            > */}
                <TabView tabs={[
                    {title: "general", content: (<General form={form} onChange={onChange} />)},
                ]} />
                {/* <div className="flex justify-center">
                    <Button title="save" className="w-1/2" />
                </div> */}
            {/* </Form> */}
        </Layout>
    )
}