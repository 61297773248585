import { API_ENDPOINT } from "./constant"

const escape = (elem) => {
    elem = elem.replace("{{", "")
    elem = elem.replace("}}", "")
    return elem
}

const escapeSubTag = (elem) => {
    elem = elem.replace("[", "")
    elem = elem.replace("]", "")
    return elem
}

const jsonParse = (v) => {
    try{
        return JSON.parse(v)
    }catch(err){
        return false
    }
}

const isUrl = (str) => {
    try{
        new URL(str);
        return true;
    }catch(e){
        return false;
    }
}


const getTenant = () => {
    return typeof window !== "undefined" ? JSON.parse(localStorage.getItem("profile") || "{}")?.tenant?._id : ""
}

export class Template {
    aside = [];
    foots = [];
    related = [];
    templates = [
        {
            name: "image", 
            val: (v) => {
                let list = jsonParse(v);
                if(!isUrl(list[0])){
                    list[0] = `${API_ENDPOINT}/storage/${getTenant()}/${list[0]}`
                }
                switch(list[3]){
                    case "center":
                        list[3] = "justify-items-center justify-center"
                        break;
                    case "left":
                        list[3] = "justify-items-start justify-start"
                        break;    
                    case "right":
                        list[3] = "justify-items-end justify-end"
                        break;
                    default:
                        list[3] = "justify-items-center justify-center"
                        break;
                }
                return `<div class="grid grid-col ${list[3]}"><div><img class="${list[2] ? list[2] : ''}" src="${list[0]}" /></div>${ list[1] ? `<div><figcaption class="img-desc text-center">${list[1]}</figcaption></div>` : ''}</div>`
            }
        },
        {name: "head", val: (v) => `<h1>${JSON.parse(v)[0]}</h1>`},
        {name: "subhead", val: (v) => `<h3>${JSON.parse(v)[0]}</h3>`},
        {name: "dictionary", val: (v) => {
            let list = jsonParse(v)
            if(list.length > 2){
                return `<DictionaryView ${list[0] == "ur" || list[0] == "ar" ? "style='direction: rtl;'" : "style='direction: ltr;'"} id="${list[1]}" title="${list[2]}" />`
            }            
            return `<DictionaryView id="${list[0]}" title="${list[1]}" />`
        }},
        {
            name: "link", 
            val: (v) => {
                let list = jsonParse(v);
                if(list){
                    if(!isUrl(list[0])){
                        list[0] = `https://localhost/${list[0]}`;
                    }
                    return `<a class="link" href="${list[0] || ""}" target="_blank">${list[1] || ""}</a>`;
                }
            }
        },
        {
            name: "foot", 
            val: (v) => {
                let list = jsonParse(v);
                if(list.length > 2){
                    return `<sup class="ref" style="direction: ${list[0] == 'en' ? 'ltr' : 'rtl'}"><a id="content-foot-${list[1]}" href="#foot-${list[1] || ""}">${list[1] || ""}</a></sup>`;
                }else{
                    return `<sup class="ref"><a id="content-foot-${list[0]}" href="#foot-${list[0] || ""}">${list[0] || ""}</a></sup>`;
                }
            }
        },
        {
            name: "list", 
            val: (v) => {
                let list = jsonParse(v);
                if(list){
                    list = list.map(li => `<li>${li}</li>`).join("");
                    return `<ul style="list-style: disc; margin: 0px 20px;">${list}</ul>`;
                }
            }
        },
        {
            name: "number-list",
            val: (v) => {
                let list = jsonParse(v);
                if(list){
                    list = list.map(li => `<li>${li}</li>`).join("");
                    return `<ol style="list-style: number; margin: 0px 20px;">${list}</ol>`;
                }
            }
        },
        {
            name: "video",
            val: (v) => {
                return `<video controls><source src="${v}" /></video>`
            }
        },
        {
            name: "quote",
            val: (v) => {
                let list = jsonParse(v);
                let footRef = "";
                if(list){
                    if(list.length > 2){
                        if(list[2] === "foot"){
                            this.addFoot(JSON.stringify(list.slice(3, list.length)));
                            footRef = `&nbsp; <span class="ref"><a class="blockquote-foot" id="content-foot-${list[3][0]}" href="#foot-${list[3][0] || ""}">${list[3][0] || ""}</a></span>`;
                        }
                    }
                    let style = ""
                    if(list[0] === "hadith-verse" || list[0] === "quranic-verse"){
                        style = "style='direction: rtl;'";
                    }
                    return `<blockquote ${style} class="${list[0]}">
                        &nbsp; <span>${list[1]}</span> ${footRef}
                    </blockquote>`;
                }
            }
        },
        {
            name: "ayah-number",
            val: (v) => {
                let list = jsonParse(v)
                return `<span class="text-center font-black ayah-number">${list[0]}</span>`
            }
        },
        {
            name: "symbol", 
            val: (v) => {
                let list = jsonParse(v);
                return `<img style="width: 28px; height: 18px; display: inline-block" src="/symbols/${list[0]}/${list[1]}.png" />`;
            }
        },
        {
            name: "font",
            val: (v) => {
                let list = jsonParse(v);
                return `<span class='${list[0]}'>${list[1]}</span>`
            }
        },
        {
            name: "meta",
            val: (v) => {
                jsonParse(v);
                return ``
            }
        },
        {
            name: "related",
            val: (v) => {
                jsonParse(v);
                return ``
            }
        },
        {
            name: "table",
            val: (v) => {
                let rows = v.match(/({.*?})/gim);
                rows = rows.map(x => {
                    let row = x.substring(1, x.length -1)
                    let cols = row.match(/(\(.*?)\)/gim);
                    cols = cols.map(c => c.substring(1, c.length -1).split(","))
                    return cols
                }).map(row => {
                    let cols = row.map(c => {
                        let head = c.find(x => x.trim() == "head");
                        let rowspan = c.find(x => x.trim().split("=")[0] == "rowspan");
                        let colspan = c.find(x => x.trim().split("=")[0] == "colspan");
                        return `<${head ? `th` : `td`} ${rowspan ? rowspan : ''} ${colspan ? colspan : ''}>${c[0].trim()}</${head ? `th` : `td`}>`;
                        
                    }).join("")
                    return `<tr>${cols}</tr>`
                }).join("")
                return `<div class="w-full overflow-x-scroll tabler-container"><table class="tabler">${rows}</table></div>`
            }
        },
        {
            name: "*",
            val: (v) => {
                let list = jsonParse(v);
                if(list.length > 2){
                    return `<${list[0]} ${list[1] == "ur" || list[1] == "ar" ? "style='direction: rtl;'" : "style='direction: ltr;'"}>${list[2]}</${list[0]}>`
                }
                return `<${list[0]}>${list[1]}</${list[0]}>`
            }
        }
    ];

    addFoot(fTemplate, _tagContent = {}){
        if(fTemplate.name === "foot"){
            this.foots.push(JSON.stringify(_tagContent));
        }
    }
    addMeta(fTemplate, _tagContent){
        if(fTemplate.name === "meta"){
            this.aside.push(JSON.stringify(_tagContent));
        }
    }
    addRelated(fTemplate, _tagContent){
        if(fTemplate.name === "related"){
            this.related.push(JSON.stringify(_tagContent));
        }
    }

    parseSubTag(v){
        if(v?.length > 0){
            let tagVal = escapeSubTag(v).split(",").map(x => x.trim());
            let fTemplate = this.templates.find(x => x.name == tagVal[0].trim());
            if(fTemplate && fTemplate !== undefined && fTemplate !== null){
                let _tagContent = tagVal.filter(x => x !== tagVal[0])
                if(fTemplate.name === "foot"){
                    let pre = _tagContent.slice(0, 1);
                    let content = _tagContent.slice(1, _tagContent.length).join(", ");
                    if(_tagContent.length > 2){
                        pre =  _tagContent.slice(0, 2);
                        content = _tagContent.slice(2, _tagContent.length).join(", ");
                    }
                    this.addFoot(fTemplate,  [...pre, content]);
                }
                if(fTemplate.name === "meta"){
                    let pre = _tagContent.slice(0, 1);
                    let content = _tagContent.slice(1, _tagContent.length).join(", ");
                    if(_tagContent.length > 2){
                        pre =  _tagContent.slice(0, 2);
                        content = _tagContent.slice(2, _tagContent.length).join(", ");
                    }
                    this.addMeta(fTemplate,  [...pre, content]);
                }
                v = v.replace(v, fTemplate?.val(JSON.stringify(_tagContent)))
            }else{
                fTemplate = this.templates.find(x => x.name == "*");
                v = v.replace(v, fTemplate?.val(JSON.stringify(tagVal.map(x => x.trim()))));
            }
        }
        return v
    }

    parseTag(element, value){
        let tagVal = escape(element).split("|").map(x => x.trim());
        let fTemplate = this.templates.find(x => x.name == tagVal[0].trim());
        if(fTemplate && fTemplate !== undefined && fTemplate !== null){
            let _tagContent = tagVal.filter(x => x !== tagVal[0])
            this.addFoot(fTemplate, _tagContent);
            this.addMeta(fTemplate, _tagContent);
            this.addRelated(fTemplate, _tagContent)
            value = value.replace(element, fTemplate?.val(JSON.stringify(_tagContent))) 
        }else{
            fTemplate = this.templates.find(x => x.name == "*");
            value = value.replace(element, fTemplate?.val(JSON.stringify(tagVal.map(x => x.trim()))))
        }
        return value
    }

    
    render(value){
        let meta = "";
        let related = "";
        if(value.length <= 0 || value == undefined || value == null){
            return  "";
        }
        value = value.split("\n").join("")
        value = String(value).replace(/(\[(.*?)\])/gim, (x) => this.parseSubTag(x));
        let litrals = value.match(/({{(.*?)}})/gim);
        if(litrals){
            for (let element of litrals){
                value = this.parseTag(element, value)
            }
        }
        if(this.foots.length > 0){
            value = `${value} <hr style="margin-top: 15px" />`;
            this.foots = this.foots.map(foot => JSON.parse(foot))
            value = `${value} <ul class="foot-notes">${this.foots.map(foot => {
                if(foot.length > 2){
                    return {id: foot[1], content: `<li style="width: 100%; text-align: ${foot[0] == 'en' ? 'left' : 'right'}; direction: ${foot[0] == 'en' ? 'ltr' : 'rtl'}"><a id="foot-${foot[1]}" href="#content-foot-${foot[1]}"><sup>${foot[1]}</sup></a>&nbsp;${foot[2]}</li>`}
                } else {
                    return {id: foot[0], content: `<li><a id="foot-${foot[0]}" href="#content-foot-${foot[0]}"><sup>${foot[0]}</sup></a>&nbsp;${foot[1]}</li>`};
                }
            }).sort((a,b) => Number(a.id) - Number(b.id) ).map(x => x.content).join("")}</ul>`
        }
        if(this.aside.length > 0){
            meta = this.aside.map(m => `<div class="article-summary p-2 ml-5 mb-2 bg-primary/[0.1]" style="width: 16em; margin: 0px 0px 5px 5px;">${JSON.parse(m)}</div>`).join("")
        }
        if(this.related.length > 0){
            related = this.related.map(m => `<ul><li>${JSON.parse(m)}</li></ul>`).join("")
        }
        const layout = `
        <div class="flex gap-2 markdown w-full p-2">
            <div class="container w-full">${value}</div>
            <div class="flex flex-col w-1./3">
                ${meta ? `<div class="flex flex-col gap-2 w-full">${meta}</div>` : ''}
                ${related ? `<div class="flex flex-col gap-2 w-full"><h3>See also</h3>${related}</div>` : ''}
            </div>
        </div>
        `
        return  layout.trim().replaceAll("\n", ""); // 
    }
}

