import gql from 'graphql-tag'

export const FETCH_RESERVATIONS = gql`
query FETCH_RESERVATIONS ($query: QueryParams) {
    GetReservations(query: $query) {
      _id
      reservationNumber
      type
      status
      checkIn
      checkOut
      customer {
        title
        email
        phone
      }
      address {
        type
        country
        city
        address
      }
      createdAt
    }
}`

export const FETCH_ONE_RESERVATION = gql`
query FETCH_ONE_RESERVATION ($id: String!){
  GetOneReservation(id: $id){
    _id
    reservationNumber
    type
    status
    checkIn
    checkOut
    customer{
      title
      email
      phone
      address{
        type
        country
        state
        city
        address
        postal
      }
      customerDetails{
        key
        value
      }
    }
    address {
      type
      country
      city
      address
    }
    spaces {
      _id
      title
      floorPlan {
        title
        floor
        status
      }
    }
    slots
    createdAt
  }
}`

export const CHECK_AVAILABLITY = gql`
query CHECK_AVAILABLITY ($date: [String!]!, $spaces: [String!]!){
  CheckAvailablity(date: $date, spaces: $spaces){
    _id
    reservationNumber
    type
    status
    checkIn
    checkOut
    spaces {
      _id
      title
      floorPlan {
        title
      }
    }
    slots
  }
}`

export const CREATE_RESERVATION = gql`
mutation CREATE_RESERVATION ($reservationInput: reservationInput!){
  CreateReservation(reservationInput: $reservationInput){
    _id
  }
}`


export const UPDATE_RESERVATION = gql`
mutation UPDATE_RESERVATION ($id: String!,$reservationInput: reservationInput!){
  UpdateReservation(id: $id, reservationInput: $reservationInput){
    _id
  }
}
`

export const UPDATE_RESERVATION_STATUS = gql`
mutation UPDATE_RESERVATION_STATUS ($id: String!,$status: String!){
  UpdateReservationStatus(id: $id, status: $status){
    _id
  }
}
`

export const DELETE_RESERVATION = gql`
mutation DELETE_RESERVATION ($id: String!){
  DeleteReservation(id: $id){
    _id
  }
}
`