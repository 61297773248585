import Button from "../../../components/common/Button";
import Dropdown from "../../../components/common/Dropdown";
import Textbox from "../../../components/common/Textbox";

export interface FloorPlanProp{
    form: Array<{title: string, floor: string, status: string}>;
    onChange: Function;
}

export default function FloorPlan(props: FloorPlanProp){
    const onClick = (e) => {
        e.preventDefault();
        props.onChange({ currentTarget: {name: "floorPlan", value: [...props.form, { title: "", floor: "", status: "" }]} })
    }
    const onRemove = (title) => {
        props.onChange({ currentTarget: {name: "floorPlan", value: [...props.form.filter(x => x.title !== title)]} })
    }
    const onChange = (key, e) => {
        const _form = [...props.form];
        _form[key][e.currentTarget.name] = e.currentTarget.value
        props.onChange({ currentTarget: {name: "floorPlan", value: [..._form]} })
    }
    return (
        <div className="flex flex-col gap-4 w-full">
            <Button title="Add" className="w-fit" icoOnly={true} icon="ico-plus w-5 h-5 bg-white" onClick={onClick} />
            {
                props.form?.map((item, key) => (
                <div key={key} className="flex flex-col md:flex-row items-center gap-2 w-full">
                    <Textbox type="text" className="w-full" name="title" label="title" value={item.title} onChange={(e) => onChange(key, e)} />
                    <Textbox type="text" className="w-full" name="floor" label="floor" value={item.floor} onChange={(e) => onChange(key, e)} />
                    <Dropdown name="status" label="status" list={[{title: "Draft", value: "draft"}, {title: "Publish", value: "publish"}]} value={item.status} onChange={(e) => onChange(key, e)} />
                    <Button icon="ico-times w-5 h-5 bg-white" icoOnly={true} title="remove" onClick={() => onRemove(item.title)} />
                </div>
                ))
            }

        </div>
    )
}