import { createContext, useEffect, useState } from "react";

export const ThemeContext = createContext([]);

export default function ThemeProvider(props: {children: any}){
    const [theme, setTheme] = useState({})
    const [alert, setAlert] = useState({icon: "", color: "bg-black text-white", message: ""})
    const [dialog, setDialog] = useState({title: "Shopbase", content: null, actions: []})
    const [loading, setLoading] = useState(false);
    const [formButton, setFormButton] = useState(null);
    return (
        <ThemeContext.Provider value={[{theme, alert, dialog, loading, formButton, setTheme, setAlert, setDialog, setLoading, setFormButton}]}>
            {props.children}
        </ThemeContext.Provider>
    )
}
