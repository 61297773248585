import { Link } from "react-router-dom";
import Datatable, { Datasource } from "../../components/container/DataTable";
import Layout from "../../layouts";
import { DELETE_PRODUCT, FETCH_PRODUCTS } from "../../graphql";
import RemoveDialog from "../../components/common/RemoveDialog";
import { __t } from "../../library/textHelper";

const DATASOURCE: Datasource = {
    columns: [
        {title: __t("id"), field: "_id" },
        {title: __t("title"), field: "title" },
        {title: __t("category"), field: "categories", template :(row) => (<div>{row.categories.map(x => x.title).join(", ")}</div>)}
    ],
    title: (row) => row["title"],
    actions: [
        (row, key) => (<Link key={key} to={`${row._id}`}><i className="ico-pencil-square w-5 h-5 bg-black">&nbsp;</i></Link>),
        (row, key) => (<RemoveDialog key={key} id={row._id} reload={true} query={DELETE_PRODUCT} />),
    ]
}

export default function Product(){
    return (
        <Layout>
            <Datatable query={FETCH_PRODUCTS} dataKey="GetProducts" newRoute="new" datasource={DATASOURCE} />
        </Layout>
    )
}