import * as axios from 'axios'
import { useContext, useEffect, useState } from "react"
import Button from "./Button";
import { API_ENDPOINT, AUTHORITY_URL, CLIENT_ID } from '../../library/constant';
import { __t, parseJson } from '../../library/textHelper';
import { ThemeContext } from '../../context/theme';

export interface ImageUploadProp{
    selected?: Array<string>;
    multiple?: boolean;
    onUpload?: Function;
}

export interface ImageViewProp{
    alt: string
    src: string
    className?: string
    onClick?: Function
}

async function readImage(file){
    return new Promise((r, j) => {
        const reader = new FileReader();
        reader.onload = e => {
            r(e.target.result)
        }
        reader.readAsDataURL(file)  
    })
}

export function ImageView(props: ImageViewProp){
    const onClick = (e) => {
        e.preventDefault()
        if(props.onClick){
            props.onClick(props.src)
        }
    }

    return (
        <div className='relative'>
            {
                props.onClick && (
                    <Button className='absolute right-0' icoOnly={true} icon='ico-trash bg-white w-5 h-5' title='delete' onClick={onClick} />
                )
            }
            <img alt={props.alt} src={props.src} className={props.className} />
        </div>
    )
}

export default function ImageUpload(props: ImageUploadProp){
    const [images, setImages] = useState([])
    const [theme] = useContext(ThemeContext);
    const onUpload = async (e) => {
        setImages([])
        let _images = []
        for(let fileIndex = 0; fileIndex < e.target.files.length; fileIndex++){
            let data = await readImage(e.target.files[fileIndex])
            _images.push({file: e.target.files[fileIndex], data})
        }
        setImages(_images);
    }

    const onRemove = (e) => {
        const _images = images.filter(x => {
            if(Object.keys(x).length > 0){
                if(x.data != e){
                    return x
                }
            }else {
                return !String(e).split("/").includes(x)
            }
        })
        props.onUpload(props.selected.filter(x => !String(e).split("/").includes(x)));
    }
    
    const upload = async (e) => {
        e.preventDefault();
        const imageNames = [];
        const headers = {};
        // const _token = JSON.parse(window.sessionStorage.getItem(`oidc.user:${AUTHORITY_URL}:${CLIENT_ID}`))
        // if(_token.id_token){
        //     headers["Authorization"] = `Bearer ${_token.id_token}`
        // }
        // const _profile = window.localStorage.getItem("profile") ? JSON.parse(window.localStorage.getItem("profile")) : null;
        // if(_profile){
        //     headers["x-tenant"] = _profile.tenant._id;
        // }
        const _profile = window.localStorage.getItem("profile") ? JSON.parse(window.localStorage.getItem("profile")) : null;
        if(_profile){
            if(_profile.token){
                headers["Authorization"] = `Bearer ${_profile.token}`
            }
            headers["x-tenant"] = _profile.tenant._id;
        }
        theme.setLoading(true)
        for(let i = 0; i < images.length; i++){
            const formData = new FormData();
            formData.append("file", images[i].file);
            const {data, status} = await axios.default.post(`${API_ENDPOINT}/storage/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    ...headers
                }
            });
            if(data){
                if(data.url){
                    imageNames.push(data.url)
                }
            }
        }
        theme.setLoading(false)
        setImages([])
        props.onUpload(imageNames);
    }

    const onChange = (e) => {
        props.onUpload([e.target.value])
    }

    return (
        <div className="relative flex flex-col gap-2 w-full">
            {/* {
                !props.multiple  && (                    
                    <input type="text" className='outline-none p-2 h-8 w-full rounded-t-md border-b-2 border-black' placeholder={__t("title image")} onChange={onChange}/>
                )
            } */}
            
            <input type="file" accept="image/*" multiple={props.multiple} onChange={onUpload} />
            {
                images.length > 0 && ( <Button title="upload" onClick={upload} /> )
            }
            
            <div className="flex gap-2 flex-wrap">
                {
                    parseJson(props.selected || "[]")?.map((image, key) => (
                        <ImageView alt="attached" key={key} src={`${API_ENDPOINT}/storage/${image}`} className="object-scale-down w-48 h-48 border-2 border-black" onClick={onRemove} />
                    ))
                }
                {
                    images.map((image, key) => (
                        <ImageView key={key} alt="to be attach" src={image.data} className="object-scale-down w-48 h-48 border-2 border-black" onClick={onRemove} />
                    ))
                }
            </div>
        </div>
    )
}