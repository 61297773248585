import gql from 'graphql-tag'

export const FETCH_MENU_TYPES = gql`
query FETCH_MENU_TYPES ($query: QueryParams) {
    GetMenuTypes(query: $query) {
    _id
    title
  }
}
`

export const FETCH_ONE_MENU_TYPE = gql`
query FETCH_ONE_MENU_TYPE ($id: String!){
  GetOneMenuType(id: $id){
    _id
    title
  }
}
`

export const CREATE_MENU_TYPE = gql`
mutation CREATE_MENU_TYPE ($menuTypeInput: menuTypeInput!){
  CreateMenuType(menuTypeInput: $menuTypeInput){
    _id
    title
  }
}
`

export const UPDATE_MENU_TYPE = gql`
mutation UPDATE_MENU_TYPE ($id: String!,$menuTypeInput: menuTypeInput!){
  UpdateMenuType(id: $id, menuTypeInput: $menuTypeInput){
    _id
    title
  }
}
`

export const DELETE_MENU_TYPE = gql`
mutation DELETE_MENU_TYPE ($id: String!){
  DeleteMenuType(id: $id){
    _id
  }
}
`

export const FETCH_MENUS = gql`
query FETCH_MENUS ($query: QueryParams, $find: menuFilter) {
    GetMenus(query: $query, find: $find) {
    _id
    title
    type
    url
    order
    image
    parent{
      _id
      title
    }
  }
}
`

export const FETCH_ONE_MENU = gql`
query FETCH_ONE_MENU ($id: String!){
  GetOneMenu(id: $id){
    _id
    title
    type
    image
    lang{
      key
      value
    }
    url
    order
    parent{
      _id
      title
    }
  }
}
`

export const CREATE_MENU = gql`
mutation CREATE_MENU ($menuInput: menuInput!){
  CreateMenu(menuInput: $menuInput){
    _id
    title
    type
  }
}
`

export const UPDATE_MENU = gql`
mutation UPDATE_MENU ($id: String!,$menuInput: menuInput!){
  UpdateMenu(id: $id, menuInput: $menuInput){
    _id
    title
    type
  }
}
`

export const DELETE_MENU = gql`
mutation DELETE_MENU ($id: String!){
  DeleteMenu(id: $id){
    _id
  }
}
`