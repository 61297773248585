import Textbox from "../../../components/common/Textbox"

export interface SocialProp{
    form: Array<{key: string, value: string}>
    onChange: Function
}

export default function Social(props: SocialProp){
    const onChange = (e) =>{
        let _form = [...props.form];
        const fItem = {..._form.find(x => x.key == e.currentTarget.name)}
        if(fItem){
            _form = _form.filter(x => x.key !== e.currentTarget.name)
        }
        _form.push({key: e.currentTarget.name, value: e.currentTarget.value})
        props.onChange({currentTarget: { name: "customSettings", value: _form }})
    }
    return (
        <div className="flex flex-col gap-4 w-full">
            <Textbox className="w-full" name="social_facebook" type="text" label="Facebook" value={props.form.find(x => x.key == "social_facebook")?.value || ""} onChange={onChange} />
            <Textbox className="w-full" name="social_instagram" type="text" label="Instagram" value={props.form.find(x => x.key == "social_instagram")?.value || ""} onChange={onChange} />
            <Textbox className="w-full" name="social_twitter" type="text" label="Twitter (X)" value={props.form.find(x => x.key == "social_twitter")?.value || ""} onChange={onChange} />
            <Textbox className="w-full" name="social_youtube" type="text" label="Youtube" value={props.form.find(x => x.key == "social_youtube")?.value || ""} onChange={onChange} />
            <Textbox className="w-full" name="social_tiktok" type="text" label="TikTok" value={props.form.find(x => x.key == "social_tiktok")?.value || ""} onChange={onChange} />
            <Textbox className="w-full" name="social_linkedin" type="text" label="Linked In" value={props.form.find(x => x.key == "social_linkedin")?.value || ""} onChange={onChange} />
            <Textbox className="w-full" name="social_whatsapp" type="text" label="Whats App" value={props.form.find(x => x.key == "social_whatsapp")?.value || ""} onChange={onChange} />
        </div>
    )
}