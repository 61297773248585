import gql from 'graphql-tag'

export const FETCH_BANNERS = gql`
query FETCH_BANNERS ($query: QueryParams) {
  GetBanners(query: $query) {
    _id
    type
    title
  }
}`

export const FETCH_ONE_BANNER = gql`
query FETCH_ONE_BANNER ($id: String!){
  GetOneBanner(id: $id){
    _id
    type
    title 
    image
  }
}`

export const CREATE_BANNER = gql`
mutation CREATE_BANNER ($bannerInput: bannerInput!){
  CreateBanner(bannerInput: $bannerInput){
    _id
  }
}`


export const UPDATE_BANNER = gql`
mutation UPDATE_BANNER ($id: String!,$bannerInput: bannerInput!){
  UpdateBanner(id: $id, bannerInput: $bannerInput){
    _id
  }
}
`

export const DELETE_BANNER = gql`
mutation DELETE_BANNER ($id: String!){
  DeleteBanner(id: $id){
    _id
  }
}
`