import { useParams } from "react-router-dom"
import Layout from "../../layouts"
import { useState } from "react"
import Form from "../../components/container/Form"
import { CREATE_BANNER, FETCH_ONE_BANNER, UPDATE_BANNER } from "../../graphql"
import Button from "../../components/common/Button"
import TabView from "../../components/container/TabView"
import General from "./components/general"

export default function BannerEditor(){
    const params = useParams()

    const [form, setForm] = useState({ 
        title: "", type: "", image: "" 
    })

    const onData = (data) => {
        setForm(data["GetOneBanner"])
    }

    const onChange = (e) => {
        const _form = {...form};
        _form[e.currentTarget.name] = e.currentTarget.value;
        setForm({..._form});
    }

    const onSave = (data) => {
    }

    const onError = (error) => {
    }

    return (
        <Layout>
            <Form 
                id={params.id}
                query={FETCH_ONE_BANNER}
                onData={onData}
                variables={{bannerInput: form}} 
                submitted={onSave}
                error={onError}
                routeBack="/banner"
                actions={(onSubmit, routeBack) => (
                    <>
                        <Button title="save" icoOnly={true} icon="ico-save w-5 h-5 bg-white" onClick={(e) => onSubmit(e, {create: CREATE_BANNER, update: UPDATE_BANNER}, {bannerInput: form}, params.id)} />
                        {
                            routeBack && (
                                <Button title="back" icoOnly={true} icon="ico-back w-5 h-5 bg-white" onClick={routeBack} />    
                            )
                        }
                    </>
                )}
            >
                <TabView
                    tabs={[
                        {title: "general", content: (<General form={form} onChange={onChange} />)},
                    ]} 
                />
            </Form>
        </Layout>
    )
}