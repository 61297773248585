import { useNavigate, useSearchParams } from "react-router-dom"
import LogoImage from '../../assets/logo.svg'
import SignupImage from '../../assets/main_3.svg'
import Button from "../../components/common/Button";
import { mutation } from "../../library/apolloClient";
import { CREATE_STORE } from "../../graphql";
import { useContext, useEffect } from "react";
import { ThemeContext } from "../../context/theme";

export default function Verify(){
    const router = useNavigate()
    const [theme] = useContext(ThemeContext);
    const params = useSearchParams();
    useEffect(() => {
        if(params[0].get("token") == undefined){
            router("/error")
        }
    }, [])
    const onSubmit = (e) => {
        e.preventDefault();
        theme.setLoading(true)
        mutation(CREATE_STORE, { token: params[0].get("token") }, "")
        .then(data => {
            theme.setAlert({...theme.alert, message: "Store has been created"})
            setTimeout(() => {
                theme.setLoading(false)
                router("/login")
            }, 2000)
        })
        .catch(err => {
            theme.setLoading(false)
            theme.setAlert({...theme.alert, message: "Token is invalid"})
        })
    }

    return (
        <div className="flex h-screen">
            <div className="flex flex-col items-center justify-start md:justify-center gap-2 w-full md:w-1/2 p-5 md:p-16 bg-white md:border-slate md:border-r-2">
                <img src={LogoImage} alt="Logo" className='w-full mb-5' />
                <form className='flex flex-col w-full gap-4' onSubmit={onSubmit}>
                    <Button className='w-full my-2' title="Continue" onClick={() => { }} />
                </form>
            </div>
            <div className="hidden md:flex flex-col bg-[#eff1f4] w-full">
                <img src={SignupImage} alt="Signup Image" className='h-full w-full' />
            </div>
        </div>
    )
}