import { useParams } from "react-router-dom"
import Layout from "../../layouts"
import { useState } from "react"
import Form from "../../components/container/Form"
import { CREATE_ORDER, FETCH_ONE_ORDER, UPDATE_ORDER } from "../../graphql"
import Button from "../../components/common/Button"
import TabView from "../../components/container/TabView"
import General from "./components/general"

export default function OrderEditor() {
    const params = useParams()
    const [form, setForm] = useState({
        orderNumber:"", type:"product", status:"pending", paymentType: "cash",
        customer: {
            title: "", email: "", phone: "", 
            address: [{ country: "", city: "", state: "", postal: "", address: "" }], 
            customerDetails: []
        },
        items: [], address:[],
        createdAt:""
    })
    
    const onData = (data) => {
        Object.assign(form, { ...form,  ...data["GetOneOrder"] })
        setForm({...data["GetOneOrder"]})
    }

    const onChange = (e) => {
        const _form = { ...form };
        if(Array.isArray(e.currentTarget)){
            for(const target of e.currentTarget){
                _form[target.name] = target.value;    
            }
        }else{
            _form[e.currentTarget.name] = e.currentTarget.value;
        }
        setForm({ ..._form });
    }

    return (
        <Layout>
            <Form
                id={params.id}
                query={FETCH_ONE_ORDER}
                onData={onData}
                variables={{ orderInput: form }}
                routeBack="/order"
                actions={(onSubmit, routeBack) => (
                    <>
                        <Button title="save" icoOnly={true} icon="ico-save w-5 h-5 bg-white" onClick={(e) => onSubmit(e, { create: CREATE_ORDER, update: UPDATE_ORDER }, {orderInput: form}, params.id)} />
                        {
                            routeBack && (
                                <Button title="back" icoOnly={true} icon="ico-back w-5 h-5 bg-white" onClick={routeBack} />    
                            )
                        }
                    </>
                )}
            >
                <TabView
                    tabs={[
                        {title: "general", content: (<General id={params.id} form={form} onChange={onChange} />)},
                    ]} 
                />
            </Form>
        </Layout>
    )
}