import { useEffect, useState } from "react";
import Dropdown from "../../../components/common/Dropdown";
import ImageUpload from "../../../components/common/ImageUpload";
import QuilEditor from "../../../components/common/QuilEditor";
import Searchtag from "../../../components/common/Searchtag";
import Textbox from "../../../components/common/Textbox";
import { FETCH_CATEGORIES, FETCH_CATEGORY_TYPES } from "../../../graphql";
import { LANGUAGES } from "../../../library/constant";
import { parseJson } from "../../../library/textHelper";

export interface GeneralProp{
    form: {
        title: string,
        body: string,
        status: string,
        lang: string,
        categories: Array<any>
        style: Array<{key: string, value: string}>
    };
    categoryType: string;
    onChange: Function;
}

export default function General(props: GeneralProp){
    const [categoryType, setcategoryType] = useState(props.categoryType)
    
    // useEffect(() => {
    //     setcategoryType(props.categoryType)
    // }, [props])

    const onCategorType = (e) => {
        setcategoryType(String(e.currentTarget.value?.title).toLowerCase() || "")
    }
    
    const onChange = (e) => {
        if(["categories", "feature"].includes(e.currentTarget.name)){
            const fCategory = props.form.categories.find(x => x._id == e.currentTarget.value._id)
            if(!fCategory){
                e = {currentTarget: {name: "categories", value: [...props.form.categories, e.currentTarget.value].map((x) => ({_id: x._id, title: x.title, type: x.type}))}}
            }
        } 
        props.onChange(e)
    }
    const onRemove = (e: {currentTarget: { name: string, value: any }}) => {
        switch(e.currentTarget.name){
            case "categories":
            case "feature":
                props.onChange({currentTarget: { name: "categories", value: props.form.categories.filter(x => x._id !== e.currentTarget.value._id) }})
                break;
            case "categoryType":
                setcategoryType("")
                break;
        }
    }

    const onUpload = (e) => {
        const _style = props.form.style.filter(x => x.key !== "featured")
        props.onChange({ currentTarget: { name: "style", value: [..._style, {key: "featured", value: JSON.stringify(e)}] } })
    }

    return (
        <div className="flex flex-col md:flex-row md:flex-row-reverse gap-4 w-full">
            <div className="flex flex-col gap-4 w-full">
                <Textbox name="title" type="text" label="title" value={props.form.title} onChange={onChange} />
                <div className="flex gap-4">
                    <Dropdown name="status" label="status" list={[{title: "Draft", value: "draft"}, {title: "Publish", value: "publish"}]} value={props.form.status} onChange={onChange} />
                    <Searchtag 
                        name="categoryType" 
                        label="category type"
                        className="flex-col flex-col-reverse"
                        query={FETCH_CATEGORY_TYPES}
                        titleKey={(row: any) => `${row.title}${row.parent?.title ? ` | ${row.parent?.title}` : ""}`}
                        dataKey="GetCategoryTypes"
                        variables={{find: {include: true}}}
                        selected={[{ title: categoryType || props.categoryType }]}
                        onSelect={(e) => onCategorType({currentTarget: {name: "categoryType", value: e}})}
                        onRemove={(e) => onRemove({ currentTarget: { name: "categoryType", value: e } })}  
                    />
                    <Searchtag 
                        name="categories" 
                        label="category"
                        className="flex-col flex-col-reverse"
                        query={FETCH_CATEGORIES}
                        titleKey={(row: any) => `${row.title}${row.parent?.title ? ` | ${row.parent?.title}` : ""}`}
                        dataKey="GetCategories"
                        variables={{find: {type: categoryType || props.categoryType}}}
                        selected={props.form.categories?.filter(x => x.type === (categoryType || props.categoryType))}
                        onSelect={(e) => onChange({currentTarget: {name: "categories", value: e}})}
                        onRemove={(e) => onRemove({ currentTarget: { name: "categories", value: e } })}  
                    />
                    <Searchtag 
                        name="feature" 
                        label="feature"
                        className="flex-col flex-col-reverse"
                        query={FETCH_CATEGORIES}
                        titleKey={(row: any) => `${row.title}${row.parent?.title ? ` | ${row.parent?.title}` : ""}`}
                        dataKey="GetCategories"
                        variables={{find: {type: "feature"}}}
                        selected={props.form.categories?.filter(x => x.type === "feature")}
                        onSelect={(e) => onChange({currentTarget: {name: "feature", value: e}})}
                        onRemove={(e) => onRemove({ currentTarget: { name: "feature", value: e } })}  
                    />
                    <Dropdown name="lang" label="language" list={LANGUAGES.map(x => ({title: x.title, value: x.code}))} value={props.form.lang} onChange={onChange} />
                </div>
                <div className="flex gap-4">
                    <ImageUpload multiple={true} selected={parseJson(props.form.style.find(x => x.key === "featured")?.value || "[]", [])} onUpload={onUpload} />
                </div>
                <QuilEditor name="body" tags={true} language={props.form.lang} onChange={(e) => onChange({currentTarget: {name: "body", value: e}})} value={props.form.body} isContent={String(props.form.body).length > 0} />
            </div>
        </div>
    )
}