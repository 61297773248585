import LOCALE from '../languages'
export const textElipse = (text: string, len: number = 15) => `${text.slice(0, len)} ${text.length > len ? '....' : ''}`

export const populateFields = (obj: any, excludeFields: Array<string> = []) => {
    let _obj: any = {};
    for(const item of Object.keys(obj)){
        if(!excludeFields.includes(item)){
            _obj[item] = obj[item]
        }
    }
    return _obj
}

export function parseJson(data: any, returnData?: any){
    try{
        switch(typeof data){
            case "string":
                if([","].includes(data)){
                    return data.split(",")
                }
                return JSON.parse(JSON.stringify([data]))
            case "undefined":
                return returnData || []
        }
        return JSON.parse(data)
    }catch(err){
        return returnData || [];
    }
}

export const __t = (key: string, plural = false) => {
    const lang = window.localStorage.getItem("language") || "en"
    let value = LOCALE[lang].default[key] || ""
    const _key = key.split(" ")
    if(_key.length > 0 && value.length <= 0){
        for(const str of ["ur"].includes(lang) ? _key.reverse() : _key){
            let val = LOCALE[lang].default[str] || str
            if((plural && lang == "en") && val.length > 0){
                val = val.split("")
                val[val.length -1] = val[val.length -1] == "y" ? "ie" : val[val.length -1] 
                val = `${val.join("")}s`  
            }
            value = `${value} ${val}`
        }
    }else{
        return LOCALE[lang].default[key] || key
    }
    return value
}


export const validation = (form: any, rules: Array<{field: string, message: string, validate: (val: any) => boolean}>) => {
    const errors: Array<{field: string, message: string}> = [];
    for(const _input of Object.keys(form)){
        const _field = rules.find(x => x.field == _input);
        if(_field){
            if(!_field.validate(form[_input])){
                errors.push({field: _field.field, message: _field.message});
            }
        }   
    }
    return errors
}