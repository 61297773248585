import Dropdown from "../../../components/common/Dropdown";
import Textbox from "../../../components/common/Textbox";

export interface GeneralProp{
    form: any;
    onChange: Function;
}

export default function General(props: GeneralProp){
    const onChange = (e) => {
        props.onChange(e)
    }   
    return (
        <div className="flex flex-col gap-4">
            <div className="flex gap-2 bg-white">
                <Textbox name="title" type="text" className="w-full" label="title" value={props.form.title} onChange={onChange} />
                <input type="checkbox" name="include" className="border-b-2 border-black" checked={props.form.include} onChange={onChange} />
                <label className="border-b-2 border-black">Include</label>
            </div>
        </div>
    )
}