import { ThemeContext } from "../../context/theme";
import { useContext, useEffect, useState } from "react";
import Title from "./Title";

export default function Dialog(){
    const [theme] = useContext(ThemeContext);
    const [isOpen, setOpen] = useState(false)
    const toggle = () => setOpen(!isOpen)
    useEffect(() => {
        if(theme.dialog.content){
            toggle();
        }
    }, [theme.dialog])

    return isOpen && (
        <div className="fixed flex left-0 top-0 h-full w-full bg-black/70 z-50">
            <div className="flex flex-col m-auto h-fit w-fit bg-white rounded-md">
                <div className="flex gap-2 text-white bg-black px-2 rounded-t-md">
                    { theme.dialog.title.length > 0 && (<Title className="flex w-full text-md font-semibold">{theme.dialog.title}</Title>) }
                    <button className="flex" onClick={toggle}>&times;</button>
                </div>
                <div className="m-2">
                    {theme.dialog.content}
                </div>
                {
                    theme.dialog.actions.length > 0 && (
                    <div className="flex justify-center gap-2 my-2">
                        {theme.dialog.actions.map((action, key) => action(key))}
                    </div>
                    )
                }
            </div>
        </div>
    )
}