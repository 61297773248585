import gql from 'graphql-tag'

export const FETCH_ORDERS = gql`
query FETCH_ORDERS ($query: QueryParams) {
    GetOrders(query: $query) {
      _id
      orderNumber
      status
      type
      customer {
        title
        email
        phone
      }
      address {
        type
        country
        city
        address
      }
      createdAt
    }
}`

export const FETCH_ONE_ORDER = gql`
query FETCH_ONE_ORDER ($id: String!){
  GetOneOrder(id: $id){
    _id
    orderNumber
    status
    type
    customer{
      _id
      title
      email
      phone
      address{
        type
        country
        city
        state
        postal
        address
      }
    }
    address {
      type
      country
      city
      address
    }
    paymentType
    items {
      product {
        _id
        title
        images
      }
      rate 
      qty 
    }
    createdAt
  }
}`

export const CREATE_ORDER = gql`
mutation CREATE_ORDER ($orderInput: orderInput!){
  CreateOrder(orderInput: $orderInput){
    _id
  }
}`


export const UPDATE_ORDER = gql`
mutation UPDATE_ORDER ($id: String!,$orderInput: orderInput!){
  UpdateOrder(id: $id, orderInput: $orderInput){
    _id
  }
}
`

export const UPDATE_ORDER_STATUS = gql`
mutation UPDATE_ORDER_STATUS ($id: String!,$status: String!){
  UpdateOrderStatus(id: $id, status: $status){
    _id
  }
}
`

export const DELETE_ORDER = gql`
mutation DELETE_ORDER ($id: String!){
  DeleteOrder(id: $id){
    _id
  }
}
`