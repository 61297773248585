import gql from 'graphql-tag'

export const FETCH_SETTINGS = gql`
query FETCH_SETTINGS {
  GetSettings {
    _id
    title
    description
    email
    customSettings{
      key
      value
    }
  }
}`


export const UPDATE_SETTINGS = gql`
mutation UPDATE_SETTINGS ($settingsInput: settingsInput!){
  UpdateSettings(settingsInput: $settingsInput){
    _id
  }
}
`

export const VERIFY_DATA = gql`
mutation VERIFY_DATA($str: String!){
  VerifyData(str: $str)
}
`

export const CREATE_STORE = gql`
mutation CREATE_STORE($token: String!){
  CreateStore(token: $token)
}
`