import { useState } from "react";
import Dropdown from "../common/Dropdown";
import { LANGUAGES } from "../../library/constant";
import Textbox from "../common/Textbox";
import QuilEditor from "../common/QuilEditor";

export interface TranslationProp{
    onChange: Function
    form: Array<{title: string, description: string, language: string}>
}

export default function Translation(props: TranslationProp){
    const [language, setLanguage] = useState("")
    
    const onChange = (e) => {
        if(e.currentTarget?.name === "language"){
            setLanguage(e.currentTarget.value)
        }else {
            props.onChange({currentTarget: {name: `lang_${language}_${e.currentTarget.name}`, value: e.currentTarget.value}})
        }
    }
    
    return (
        <div className="flex flex-col gap-4">
            <Dropdown name="language" label="language" onChange={onChange} value={language} list={LANGUAGES.map(x => ({title: x.title, value: x.code}))} />
            <Textbox className="w-full" type="text" name="title" label="title" value={props.form.find(x => x.language === language)?.title || ""} onChange={onChange} />
            <QuilEditor tags={true} language={language} name="description" value={props.form.find(x => x.language === language)?.description || ""} isContent={(props.form.find(x => x.language === language)?.description || "").length > 0} onChange={(e) => onChange({currentTarget: {name: `description`, value: e}})} />
        </div>
    )
}