import { useParams } from "react-router-dom"
import Layout from "../../layouts"
import { useState } from "react"
import Form from "../../components/container/Form"
import { CREATE_RESERVATION, FETCH_ONE_RESERVATION, UPDATE_RESERVATION } from "../../graphql"
import Button from "../../components/common/Button"
import TabView from "../../components/container/TabView"
import General from "./components/general"
import Customer from "./components/customer"
import moment from "moment"

export default function ReservationEditor() {
    const params = useParams()
    const [form, setForm] = useState({
        reservationNumber:"", type:"room", status:"pending",
        checkIn:moment().format("YYYY-MM-DD"), checkOut: moment().format("YYYY-MM-DD"),
        customer: {
            title: "", email: "", phone: "", 
            address: [{ country: "", city: "", state: "", postal: "", address: "" }], 
            customerDetails: []
        }, 
        address:[], spaces:[], slots:[],
        createdAt:""
    })
    
    const onData = (data) => {
        Object.assign(form, { ...data["GetOneReservation"] })
        setForm({...data["GetOneReservation"]})
    }

    const onChange = (e) => {
        const _form = { ...form };
        if(Array.isArray(e.currentTarget)){
            for(const target of e.currentTarget){
                _form[target.name] = target.value;    
            }
        }else{
            _form[e.currentTarget.name] = e.currentTarget.value;
        }
        setForm({ ..._form });
    }

    return (
        <Layout>
            <Form
                id={params.id}
                query={FETCH_ONE_RESERVATION}
                onData={onData}
                variables={{ reservationInput: form }}
                routeBack="/reservation"
                actions={(onSubmit, routeBack) => (
                    <>
                        <Button title="save" icoOnly={true} icon="ico-save w-5 h-5 bg-white" onClick={(e) => onSubmit(e, { create: CREATE_RESERVATION, update: UPDATE_RESERVATION }, {reservationInput: form}, params.id)} />
                        {
                            routeBack && (
                                <Button title="back" icoOnly={true} icon="ico-back w-5 h-5 bg-white" onClick={routeBack} />    
                            )
                        }
                    </>
                )}
            >
                <TabView
                    tabs={[
                        {title: "general", content: (<General id={params.id} form={form} onChange={onChange} />)},
                        {title: "Customer", content: (<Customer form={form.customer} onChange={onChange} />)}
                    ]} 
                />
            </Form>
        </Layout>
    )
}