import { useContext, useEffect, useState } from "react";
import Textbox from "./Textbox";
import { DocumentNode } from "graphql";
import { query } from "../../library/apolloClient";
import { ThemeContext } from "../../context/theme";
import { __t } from "../../library/textHelper";

export interface SearchtagProp{
    label: string
    name: string;
    query: DocumentNode;
    dataKey: string;
    titleKey?: (row: any) => string;
    variables?: any;
    selected: Array<any>
    onSelect: Function;
    onRemove?: Function;
    className?: string;
}

export default function Searchtag(props: SearchtagProp){
    const [theme] = useContext(ThemeContext)
    const [form, setForm] = useState({value: ""})
    const [list, setList] = useState([])
    const [isLoading, setLoading] = useState(false);

    const onFocus = (e) => {
        setLoading(true)
        query(props.query,{...(props.variables || {}), query: {limit:100,...(props.variables?.query || {})}}, {})
        .then(({data}) => {
            setLoading(false)
            if(typeof data !== "undefined"){
                setList(data[props.dataKey])
            }
        }).catch(error => {
            setLoading(false)
        })
    }

    const onBlur = (e) => {
        // setList([])
        // setForm({ value: "" })
    }

    const onChange = (e) => {
        setForm({...form, value: e.target.value})
        setLoading(true)    
        query(props.query,{...(props.variables || {}), query: {limit:100,...(props.variables?.query || {}), q: JSON.stringify({title: e.target.value})}}, {})
        .then(({data}) => {
            setLoading(false)
            setList(data[props.dataKey])
        })
        .catch(error => {
            setLoading(false)
        })
    }
    const onSelect = (e) => {
        props.onSelect(e)
        setList([])
        setForm({ value: "" })
    }

    const onClose = (e) => {
        e.preventDefault();
        setList([])
        setForm({ value: "" })
    }

    return (
        <div className="relative flex flex-col w-full">
            <div className={`flex rounded-md bg-white ${props.className}`}>
                { props.selected.length > 0 && props.selected.filter(x => x.title?.length > 0).map((x, key) => (
                    <div 
                        key={key} 
                        className="flex items-center bg-black text-white px-2"
                    >
                        <span className="flex w-full">{x.title}</span>{props.onRemove && (<i className="ico-times bg-white w-4 h-4 cursor-pointer" onClick={() => props.onRemove(x)}>&nbsp;</i>)}
                    </div>
                )) }
                <div className="flex">
                    <Textbox type="text" className="w-full" name={props.name} label={props.label} value={form.value} onFocus={onFocus} onBlur={onBlur} onChange={onChange} />
                    {
                        (list.length > 0 || form.value.length > 0) && (
                            <button className="px-2 border-b-2 border-black" onClick={onClose}><i className="ico-times w-5 h-5 bg-black"></i></button>
                        )
                    }
                </div>
            </div>
            {
                isLoading && (<div className="absolute bg-white top-8 p2 rounded-md w-full italic">{__t("loading")}....</div>)
            }
            {
                list.length > 0 && (
                <ul className="absolute bg-white w-full max-h-96 overflow-scroll top-8 z-50 p-2 border-2 border-black rounded-md">
                    {
                        list.map((x, key) => (<li className="cursor-pointer hover:bg-gray-200" key={key} onClick={() => onSelect(x)}>{typeof props.titleKey == "function" ? props.titleKey(x) : x.title}</li>))
                    }
                </ul>
                )
            }
        </div>
    )
}