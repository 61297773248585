import gql from 'graphql-tag'

export const FETCH_ROOMS = gql`
query FETCH_ROOMS ($query: QueryParams) {
  GetRooms(query: $query) {
    _id
    title
    rate
    status
  }
}`

export const FETCH_ROOM_DETAILS = gql`
query FETCH_ROOM_DETAILS ($query: QueryParams){
  GetRooms(query: $query){
    _id
    title
    rate
    discount
    discountType
    body
    floorPlan{
      title
      floor
      status
    }
    occupency{
      baseAdult
      baseChildren
      maxAdult
      maxChildren
    }
    roomDetails{
      key
      value
    }
    products{
      _id
      title
    }
    categories{
      _id
      title
      type
    }
  }
}`

export const FETCH_ONE_ROOM = gql`
query FETCH_ONE_ROOM ($id: String!){
  GetOneRoom(id: $id){
    _id
    title
    rate
    discount
    discountType
    body
    floorPlan{
      title
      floor
      status
    }
    occupency{
      baseAdult
      baseChildren
      maxAdult
      maxChildren
    }
    roomDetails{
      key
      value
    }
    products{
      _id
      title
    }
    categories{
      _id
      title
      type
    }
  }
}`

export const CREATE_ROOM = gql`
mutation CREATE_ROOM ($roomInput: roomInput!){
  CreateRoom(roomInput: $roomInput){
    _id
  }
}`


export const UPDATE_ROOM = gql`
mutation UPDATE_ROOM ($id: String!,$roomInput: roomInput!){
  UpdateRoom(id: $id, roomInput: $roomInput){
    _id
  }
}
`

export const DELETE_ROOM = gql`
mutation DELETE_ROOM ($id: String!){
  DeleteRoom(id: $id){
    _id
  }
}
`