import { useNavigate, useLocation } from 'react-router-dom'
import { ReactNode, useContext, useEffect, useState } from "react";
import { mutation, query } from "../../library/apolloClient";
import { ThemeContext } from "../../context/theme";
import { DocumentNode } from "graphql";

export interface FormProp{
    id?: string
    children: ReactNode
    query: DocumentNode,
    variables: any,
    onData: Function,
    submitted?: Function,
    error?: Function
    routeBack?: string;
    actions?: (callback: (e: any, mutations:{create: DocumentNode, update: DocumentNode},variables: any, id?: string) => void, routeBack?: () => void) => void;
}

export default function Form(props: FormProp){
    const navigate = useNavigate()
    const pathname = useLocation().pathname
    const [theme] = useContext(ThemeContext);
    const [variables, setVariables] = useState(props.variables)
    useEffect(() => {
        if(props.id != "new"){
            setVariables({id: props.id, ...props.variables})
            theme.setLoading(true)    
            query(props.query,{id: props.id}, {})
            .then(({data}) => {
                theme.setLoading(false)
                props.onData(data)
            })
            .catch(error => {
                theme.setLoading(false)
                theme.setAlert({...theme.alert, message: "An error occurd while fetching"})
                if(props.error){
                    props.error(error)
                }
            })
        }
    }, []);

    useEffect(() => {
        setVariables({...variables, ...props.variables})
        if(props.actions){
            theme.setFormButton(props.actions(onSubmit, props.routeBack ? () => navigate(props.routeBack) : null))
        }
    }, [props.variables]);

    const onSubmit = (e: any, mutations:{create: DocumentNode, update: DocumentNode},variables: any, id?: string) => {
        e.preventDefault()
        theme.setLoading(true)
        let _variables: any = {...variables}
        if(!["", "new"].includes(id)){
            _variables["id"] = id
        }
        mutation(id != "new" ? mutations.update : mutations.create, {..._variables}, {})
        .then((data) => {
            theme.setLoading(false)
            theme.setAlert({...theme.alert, message: "Record has been saved"})
            if(typeof data?.data !== "undefined"){
                const key = Object.keys(data.data)[0];
                navigate(pathname.replace("new", data.data[key]._id))
            }
            if(props.submitted){
                props.submitted(data)
            }
            // if(props.routeBack?.length > 0){
            //     navigate(props.routeBack)
            // }
        })
        .catch(error => {
            theme.setLoading(false)
            theme.setAlert({...theme.alert, message: "An error occurd while saving record"})
            if(props.error){
                props.error(error)
            }
            
        })
    }

    return (
        <form>
            {props.children}
        </form>
    )
}