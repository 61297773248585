import { __t } from "../../library/textHelper"

export interface ButtonProp{
    title: string
    className?: string
    activeClassName?: string
    onClick?: any
    icon?: string
    icoOnly?: boolean
    disabled?: boolean
}
export default function Button(props: ButtonProp){
    return (
        <button 
            disabled={props.disabled}
            onClick={props.onClick} 
            className={`flex justify-center text-center items-center gap-2 p-2 ${props.activeClassName || "font-bold bg-black text-white"} rounded-md ${props.className}`}
        >
            {
                props.icoOnly ? 
                props.icon && <i className={props.icon}>&nbsp;</i> : 
                (<div className="flex gap-2 items-center justify-center w-full">{props.icon && <i className={props.icon}>&nbsp;</i>}<span className="capitalize w-full text-center">{__t(props.title)}</span></div>)
            }
        </button>
    )
}