import gql from 'graphql-tag'

export const FETCH_ALL_MEDIA = gql`
query FETCH_ALL_MEDIA ($query: QueryParams, $find: mediaFilter) {
    GetAllMedia(query: $query, find: $find) {
        _id
        title
        slug
        url
    }
}
`

export const FETCH_ONE_MEDIA = gql`
query FETCH_ONE_MEDIA ($id: String!){
  GetOneMedia(id: $id){
    _id
    title
    slug
    url
  }
}
`

export const UPDATE_MEDIA = gql`
mutation UPDATE_MEDIA ($id: String!,$mediaInput: mediaInput!){
  UpdateMedia(id: $id, mediaInput: $mediaInput){
    _id
    title
    slug
    url
  }
}
`

export const DELETE_MEDIA = gql`
mutation DELETE_MEDIA ($key: String!){
  DeleteMedia(key: $key){
    _id
  }
}
`