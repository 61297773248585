import { useEffect, useState } from "react"
import Button from "../components/common/Button"
import Title from "../components/common/Title"

export default function Profile(){
    const [isOpen, toggle] = useState(false)
    const [profile] = useState(JSON.parse(window.localStorage.getItem("profile") || "{}"))
    
    return (
        <div className="relavtive flex justify-end">
            <Button title="Profile" icoOnly={true} icon="ico-user w-5 h-5 bg-white" onClick={() => toggle(!isOpen)} />
            {
                isOpen && (
                    <div className="flex flex-col items-center absolute top-12 right-0 bg-white w-full md:w-1/3 p-2 rounded-md shadow-md">
                        <i className="ico-user bg-black w-[4.25rem] h-[4.25rem]">&nbsp;</i>
                        <Title>{profile?.name}</Title>
                        <Title>{profile?.email}</Title>
                    </div>
                )
            }
        </div>
    )
}