import { ThemeContext } from "../../context/theme";
import { useContext } from "react";

export interface LoadingProp{
    locale?: string
}

export default function Loading(props: LoadingProp){
    const [theme] = useContext(ThemeContext);

    return theme.loading && (
        <div className="flex justify-center items-center fixed h-screen w-screen z-50 bg-black/70 text-[36px] text-white font-bold">
            <span>Loading....</span>
        </div>
    )
}