import Layout from ".";
import Title from "../components/common/Title";
import { __t } from "../library/textHelper";


export default function PageNotFound(){
    return (
        <Layout>
            <div className="flex items-center justify-center w-full h-full">
                <Title className="text-[32px] text-slate-400">{__t("404")} | {__t("not found")}</Title>
            </div>
        </Layout>
    )
}