import { Link, useSearchParams } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import { DocumentNode } from "graphql"
import { query } from "../../library/apolloClient"
import SearchBox from "../common/Searchbox"
import { ThemeContext } from "../../context/theme"
import Listview from "./ListView"
import { LIMIT } from "../../library/constant"
import Button from "../common/Button"
import Pagination from "../common/Pagination"
import { __t } from "../../library/textHelper"
import Dropdown from "../common/Dropdown"


export interface Datasource {
    columns: Array<{title: string, field: string, template?: Function}>;
    title?: Function;
    subTitle?: Function;
    actions?: Array<Function> 
}

export interface DatatableProp{
    newRoute?: string
    query: DocumentNode
    variables?: any
    dataKey: string
    cacheKey?: string
    datasource: Datasource
    view?: string
}

export default function Datatable(props: DatatableProp){
    const [searchParams, setSearchParams] = useSearchParams()
    const [data, setData] = useState([])
    const [search, setSearch] = useState(searchParams.get("q") || "")
    const [view, setView] = useState(props.view || window.localStorage.getItem("view") || "list")
    const [theme] = useContext(ThemeContext)

    useEffect(() => {
        const _search = new URLSearchParams()
        _search.append("limit", searchParams.get("limit") || String(LIMIT))
        _search.append("page", "1")
        _search.append("q", search)
        setSearchParams(_search)
        // const _bounce = setTimeout(() => {
        // }, 1000)
        // return () => clearTimeout(_bounce)
    }, [search])

    const getData = () => {
        theme.setLoading(true)
        query(props.query, {
            query: {
                limit: Number(searchParams.get("limit") || LIMIT), 
                page: Number(searchParams.get("page") || 1), 
                q: searchParams.get("q") && JSON.stringify({"title": searchParams.get("q")})
        }, ...(props.variables || {})}, [])
        .then(({data}) => {
            setData(data[props.dataKey])
            theme.setLoading(false)
        })
        .catch(error => {
            theme.setLoading(false)
        })
    }

    const getDataCache = () => {
        if(typeof window !== "undefined" && Object.keys(window).includes("caches")){
            theme.setLoading(true)
            setData([])
            theme.setLoading(false)
        }
    }

    const setViewStore = (view) => {
        setView(view)
        window.localStorage.setItem("view", view);
    }

    const onSearch = (e) => setSearch(e.currentTarget.value)
    
    const setLimit = (e) => {
        searchParams.set("limit", String(e.target.value));
        setSearchParams(searchParams)
    }

    const setListType = (e) => {
        searchParams.set("data_type", e);
        setSearchParams(searchParams)
    }

    useEffect(() => {
        if(searchParams.get("data_type") == "cache"){
            getDataCache()
        }else{
            getData()
        }
    }, [searchParams])

    return (
        <div className="flex flex-col gap-2 my-2">
            <div className="flex flex-col md:flex-row gap-2">
                {/* <Button title="active" activeClassName={searchParams.get("data_type") == "cache" ? "" : "font-bold bg-white text-black"} onClick={() => setListType("")} /> */}
                {
                    props.cacheKey && (
                        <Button title="cache" activeClassName={searchParams.get("data_type") == "cache" ? "font-bold bg-white text-black" : ""} onClick={() => setListType("cache")} />
                    )
                }
                
                <div className="flex flex-col md:flex-row gap-2 w-full justify-end px-2">
                    <SearchBox text={search} onChange={onSearch} onClear={() => setSearch("")} />
                    <div className="flex gap-2">
                        <Dropdown name="limit" className="w-full md:w-14" list={[{title: "10", value: "10"}, {title: "20", value: "20"}, {title: "50", value: "50"}, {title: "100", value: "100"}]} label="Limit" value={Number(searchParams.get("limit") || LIMIT)} onChange={setLimit} />
                        {
                            props.newRoute && (<Link className="flex items-center p-2 font-bold bg-black text-white rounded-md" to={props.newRoute}><i className="ico ico-plus bg-white w-5 h-5"></i></Link>)
                        }
                        <Button title="list" icoOnly={true} icon="ico-list bg-white w-5 h-5" onClick={() => setViewStore("list")} />
                        <Button title="grid" icoOnly={true} icon="ico-grid bg-white w-5 h-5" onClick={() => setViewStore("grid")} />
                    </div>
                </div>
            </div>
            <Listview className="md:hidden flex flex-col gap-2" data={data} title={props.datasource.title} subTitle={props.datasource.subTitle} actions={props.datasource.actions || []} />
            <div className="hidden md:flex w-full">
                {
                    view == "list" ? (
                        <table className="table-auto w-full">
                            <thead className="bg-black">
                                <tr className="bg-black text-white">
                                    {
                                        props.datasource.columns.map((col, key) => (<th key={key}>{col.title}</th>))
                                    }
                                    {
                                        props.datasource.actions?.length > 0 && (<th>{__t("action")}</th>)
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data?.map((item, key) => (
                                        <tr className="border-2 text-center" key={key}>
                                            { props.datasource.columns.map((col, key) =>  col.template ? (<td key={key}>{col.template(item)}</td>) : ( <td key={key}>{item[col.field]}</td> )) }
                                            { props.datasource.actions?.length > 0 && ( <td className="flex gap-2 justify-center" key={key}>{ props.datasource.actions?.map((action, key) => action(item, key)) }</td> ) }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                     ) : ( 
                        <Listview className="flex flex-col md:grid md:grid-cols-4 gap-2" data={data} title={props.datasource.title} subTitle={props.datasource.subTitle} actions={props.datasource.actions || []} />
                    )
                }
            </div>
            <Pagination limit={Number(searchParams.get("limit") || LIMIT)} data={data} className="flex gap-2 justify-end" />
        </div>
    )
}