import { useContext, useEffect, useState } from "react"
import Textbox from "../../../components/common/Textbox"
import Expendable from "../../../components/container/Expandable"
import moment from "moment"
import Button from "../../../components/common/Button"
import { query } from "../../../library/apolloClient"
import { CHECK_AVAILABLITY, FETCH_ROOM_DETAILS } from "../../../graphql"
import { ThemeContext } from "../../../context/theme"
import { useSearchParams } from "react-router-dom"
import { LIMIT } from "../../../library/constant"
import Pagination from "../../../components/common/Pagination"

export interface GeneralProp{
    id: string;
    form: {
        reservationNumber: string, type: string, status: string,
        checkIn: string, checkOut: string,
        customer: any, address: Array<any>, 
        spaces: Array<any>, 
        slots: Array<string>,
        createdAt: string
    }
    onChange: Function
}

export default function General(props: GeneralProp){
    const [theme] = useContext(ThemeContext);
    const [searchParams] = useSearchParams()
    const [data, setData] = useState([])
    const [availabilities, setAvailabilities] = useState([])
    const [slots, setSlots] = useState([])

    useEffect(() => {
        getData()
        // setData(props.form.spaces)
        setSlots(props.form.slots)
    }, [searchParams])

    const onChange = (e) => {
        props.onChange(e)
    }

    const onFind = (e) => {
        e.preventDefault();
        getData()
    }

    const getData = async (variables = {}) => {
        theme.setLoading(true)
        try{
            const { data } = await query(FETCH_ROOM_DETAILS, {
                query: {
                    limit: Number(searchParams.get("limit") || LIMIT), 
                    page: Number(searchParams.get("page") || 1), 
                    q: searchParams.get("q") && JSON.stringify({"title": searchParams.get("q")})
            }, ...(variables || {})}, [])
            const ca = await Promise.all(
                data["GetRooms"].map(x => checkAvailability([props.form.checkIn, props.form.checkOut], [x._id]))
            )
            setAvailabilities(ca.reduce((i,j) => [...i, ...j]))
            setData(data["GetRooms"])
        }catch(err){
        }
        theme.setLoading(false)
    }

    const checkAvailability = async (dates: Array<string>, spaces: Array<string>) => {
        const { data } = await query(CHECK_AVAILABLITY, { date: dates, spaces: spaces }, [])
        let _d = [];
        for(const reservation of data["CheckAvailablity"]){
            _d.push(...reservation.slots.map(x => ({slot: x, checkIn: reservation.checkIn, checkOut: reservation.checkOut})))
        }
        return _d
    }

    const onReserve = (e, room, slot) => {
        e.preventDefault();
        let _slots = [...props.form.slots]
        let _spaces = [...props.form.spaces]
        if(_slots.find(x => x == slot)){
            _slots = _slots.filter(x => x !== slot);
        }else{
            _slots.push(slot)
        }
        if(_spaces.find(x => x._id == room._id)){
            _spaces = _spaces.filter(x => x._id !== room._id)
        }else{
            _spaces.push(room)
        }
        props.onChange({ currentTarget: [{ name: "spaces", value: [..._spaces] }, { name: "slots", value: [..._slots] }] })
    }

    return (
        <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-row gap-2">
                <Textbox type="date" name="checkIn" className="w-full" label="Check In" value={moment(props.form.checkIn || moment()).format("YYYY-MM-DD")} onChange={onChange} />
                <Textbox type="date" name="checkOut" className="w-full" label="Check Out" value={moment(props.form.checkOut || moment()).format("YYYY-MM-DD")} onChange={onChange} />
                <Button title="Find" onClick={onFind} />
            </div>
            {
                data.map((space, key) => (
                    <Expendable key={key} expended={true} title={space.title} subTitle={`Adult: ${space.occupency?.maxAdult}, Children: ${space.occupency?.maxChildren}`}>
                        <div className="mt-5">
                            <div className="flex gap-2">
                                {
                                    space.floorPlan.map((room, key) => (
                                        <button onClick={(e) => onReserve(e, space, room.title)} disabled={(availabilities.find(x => x.slot == room.title) && (!props.form.slots.includes(room.title) || slots.includes(room.title)))} className={`border-2 border-black rounded-md p-2 ${(props.form.slots.includes(room.title)  || slots.includes(room.title)) ? 'bg-orange-600' : availabilities.find(x => x.slot == room.title) ? 'bg-red-600' : 'bg-green-600'} text-white`} key={key}>{room.title}</button>
                                    ))
                                }
                            </div>
                        </div>
                    </Expendable>
                ))
            }
            <Pagination limit={LIMIT} className="flex justify-end gap-2" data={data} />
        </div>
    )
}