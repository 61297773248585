import Textbox from "../../../components/common/Textbox";

export interface OccupencyProp{
    form: {baseAdult: number, baseChildren: number, maxAdult: number, maxChildren: number};
    onChange: Function;
}

export default function Occupency(props: OccupencyProp){
    const onChange = (e) => {
        const _form = {...props.form};
        _form[e.currentTarget.name] = Number(e.currentTarget.value)
        props.onChange({currentTarget: {name: "occupency", value: { ..._form }}})
    }

    return (
        <div className="flex flex-col md:flex-row gap-4 w-full">
            <Textbox className="w-full" name="baseAdult" type="number" label="Base Adult" value={props.form.baseAdult} onChange={onChange} />
            <Textbox className="w-full" name="baseChildren" type="number" label="Base Children" value={props.form.baseChildren} onChange={onChange} />
            <Textbox className="w-full" name="maxAdult" type="number" label="Max Adult" value={props.form.maxAdult} onChange={onChange} />
            <Textbox className="w-full" name="maxChildren" type="number" label="Max Children" value={props.form.maxChildren} onChange={onChange} />
        </div>
    )
}