import { useParams } from "react-router-dom"
import Layout from "../../layouts"
import { useState } from "react"
import Form from "../../components/container/Form"
import { CREATE_CATEGORY_TYPE, FETCH_ONE_CATEGORY_TYPE, UPDATE_CATEGORY_TYPE } from "../../graphql"
import Button from "../../components/common/Button"
import TabView from "../../components/container/TabView"
import General from "./components/general"
import Translation from "../../components/addons/translation"

export default function CategoryTypeEditor(){
    const params = useParams()
    const [form, setForm] = useState({ title: "", include: false})

    const onData = (data) => {
        setForm(data["GetOneCategoryType"])
    }

    const onChange = (e) => {
        const _form = {...form};
        if(["include"].includes(e.currentTarget.name)){
            _form[e.currentTarget.name] = Boolean(e.currentTarget.checked)
        }else{
            _form[e.currentTarget.name] = e.currentTarget.value;
        }
        setForm({..._form});
    }

    const onSave = (data) => {
    }

    const onError = (error) => {
    }

    return (
        <Layout>
            <Form 
                id={params.id}
                query={FETCH_ONE_CATEGORY_TYPE}
                onData={onData} 
                variables={{categoryTypeInput: form}} 
                submitted={onSave}
                error={onError}
                routeBack="/category-type"
                actions={(onSubmit, routeBack) => (
                    <>
                        <Button title="save" icoOnly={true} icon="ico-save w-5 h-5 bg-white" onClick={(e) => onSubmit(e, { create: CREATE_CATEGORY_TYPE, update: UPDATE_CATEGORY_TYPE }, {categoryTypeInput: form}, params.id)} />
                        {
                            routeBack && (
                                <Button title="back" icoOnly={true} icon="ico-back w-5 h-5 bg-white" onClick={routeBack} />    
                            )
                        }
                    </>
                )}
            >
                <TabView
                    tabs={[
                        {title: "general", content: (<General form={form} onChange={onChange} />)}
                    ]} 
                />
            </Form>
        </Layout>
    )
}