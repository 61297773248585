import { useContext, useState } from 'react';
import LogoImage from '../../assets/logo.svg'
import SignupImage from '../../assets/main_1.svg'
import Button from "../../components/common/Button";
import Dropdown from '../../components/common/Dropdown';
import Textbox from '../../components/common/Textbox';
import { mutation } from '../../library/apolloClient';
import { INVITE_USER } from '../../graphql';
import { ThemeContext } from '../../context/theme';
import { Link, useNavigate } from 'react-router-dom';
import Title from '../../components/common/Title';
import { validation } from '../../library/textHelper';

const rules = [
    {
        field: "email",
        message: "Please enter valid email address",
        validate: (val) => {
            return String(val).match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/gim)?.length > 0
        }
    },
    {
        field: "password", 
        message: "Your password should at least 8 characters with special characters and numbers included",
        validate: (val) => {
            return String(val).match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!^%*?&]{8,128}$/gim)?.length > 0
        }
    },
    {
        field: "businessType", 
        message: "Please select any business type",
        validate: (val) => String(val).length > 2
    },
    {
        field: "businessName",
        message: "Please enter business name should be greater than 2 characters",
        validate: (val) => String(val).length > 2
    },
    {
        field: "name",
        message: "Please enter name should be greater than 2 characters",
        validate: (val) => String(val).length > 2
    },
    {
        field: "language",
        message: "Please select any language",
        validate: (val) => String(val).length >= 2
    }
]

const businessTypes: Array<{title: string, value: string}> = [
    { title: "Mini Shop", value: "mini-shop" }, 
    { title: "Blogger", value: "blogger" } 
]

export default function Register() {
    const [theme] = useContext(ThemeContext)
    const router = useNavigate()
    const [form, setForm] = useState({
        businessType: "", businessName: "", name: "", email: "", password: "", phone: "", language: "en"
    })
    const [errors, setErrors] = useState<Array<{field: string, message: string}>>([]);
    const onChange = (e) => {
        const _form = {...form};
        _form[e.target.name] = e.target.value;
        setForm(_form);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const _errors = validation(form, rules);
        setErrors(_errors);
        if(_errors.length <= 0){
            theme.setLoading(true)
            mutation(INVITE_USER, {userToken: btoa(JSON.stringify(form))}, "")
            .then(data => {
                theme.setLoading(false)
                router("/thank-you")
            })
            .catch(error => {
                theme.setLoading(false)
                theme.setAlert({...theme.alert, message: "Something went wrong"})
            })
        }
    }


    return (
        <div className="container mx-auto">
            <div className="flex flex-col items-center justify-start md:justify-center gap-2 w-full md:w-1/2 p-5 md:p-16 bg-white md:border-slate md:border-r-2">
                <img src={LogoImage} alt="Logo" className='w-full mb-5' />
                <form onSubmit={onSubmit} className='flex flex-col w-full gap-4'>
                    <Dropdown name="language" label='Language' list={[{ title: "English", value: "en" }, { title: "Urdu", value: "ur" }]} error={errors.find(x => x.field == "language")?.message} value={form.language} onChange={onChange} />
                    <Dropdown name='businessType' label='Business Type' list={businessTypes} error={errors.find(x => x.field == "businessType")?.message} value={form.businessType} onChange={onChange} />
                    <Textbox className='w-full' type="text" name="businessName" label='Business Name' error={errors.find(x => x.field == "businessName")?.message} value={form.businessName} onChange={onChange} />
                    <Textbox className='w-full' type="text" name="name" label='Name' error={errors.find(x => x.field == "name")?.message} value={form.name} onChange={onChange} />
                    <Textbox className='w-full' type="text" name="email" label='Email Address' error={errors.find(x => x.field == "email")?.message} value={form.email} onChange={onChange} />
                    <Textbox className='w-full' type="text" name="phone" label='Phone Number' error={errors.find(x => x.field == "phone")?.message} value={form.phone} onChange={onChange} />
                    <Textbox className='w-full' type="password" name="password" label='Password' error={errors.find(x => x.field == "password")?.message} value={form.password} onChange={onChange} />
                    <Button className='w-full my-2' title="Sign up with email" onClick={onSubmit} />
                    <div className='flex w-full justify-end'>
                        <Title>Already account? <Link to={"/login"} className='underline'>Login</Link></Title>
                    </div>
                </form>
                {/* <Button className='w-full my-2' title="Sign up with Facebook" onClick={() => { }} /> */}
                {/* <Button className='w-full my-2' title="Sign up with Google" onClick={() => { }} /> */}
            </div>
            <div className="hidden md:flex flex-col bg-[#eff1f4] w-full">
                <img src={SignupImage} alt="Signup Image" className='h-full w-full' />
            </div>
        </div>
    )
}