import Textbox from "../../../components/common/Textbox"

export interface EmailProp{
    form: Array<{key: string, value: string}>
    onChange: Function
}

export default function Email(props: EmailProp){
    const onChange = (e) =>{
        let _form = [...props.form];
        const fItem = {..._form.find(x => x.key == e.currentTarget.name)}
        if(fItem){
            _form = _form.filter(x => x.key !== e.currentTarget.name)
        }
        _form.push({key: e.currentTarget.name, value: e.currentTarget.value})
        props.onChange({currentTarget: { name: "customSettings", value: _form }})
    }

    return (
        <div className="flex flex-col gap-4 w-full">
            <Textbox className="w-full" name="email_hostname" type="text" label="Host" value={props.form.find(x => x.key == "email_hostname")?.value || ""} onChange={onChange} />
            <Textbox className="w-full" name="email_username" type="text" label="Username" value={props.form.find(x => x.key == "email_username")?.value || ""} onChange={onChange} />
            <Textbox className="w-full" name="email_password" type="password" label="Password" value={props.form.find(x => x.key == "email_password")?.value || ""} onChange={onChange} />
        </div>
    )
}