import { Link } from "react-router-dom";
import Datatable, { Datasource } from "../../components/container/DataTable";
import Layout from "../../layouts";
import { DELETE_CONTENT, FETCH_CONTENTS } from "../../graphql";
import RemoveDialog from "../../components/common/RemoveDialog";
import { __t } from "../../library/textHelper";

const DATASOURCE: Datasource = {
    columns: [
        { title: __t("id"), field: "_id" },
        { title: __t("title"), field: "title" },
        { title: __t("type"), field: "type" },
        { title: __t("status"), field: "status" },
        { title: __t("category"), field: "categories", template: (row, value) => (<div className="flex flex-col">{row["categories"].map(x => <span>{x.title}</span>)}</div>) }
    ],
    title: (row) => row["title"],
    subTitle: (row) => row["status"],
    actions: [
        (row, key) => (<Link key={key} to={`${row._id}`}><i className="ico-pencil-square w-5 h-5 bg-black">&nbsp;</i></Link>),
        (row, key) => (<RemoveDialog key={key} id={row._id} reload={true} query={DELETE_CONTENT} />),
    ]
}

export default function Blog(){
    return (
        <Layout>
            <Datatable query={FETCH_CONTENTS} variables={{find: {type: JSON.stringify({$nin: ["feature", "page", "brand", "product"]}) }}} dataKey="GetContents" newRoute="new" datasource={DATASOURCE} />
        </Layout>
    )
}