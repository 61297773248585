import Layout from "../layouts";
import Title from "../components/common/Title";

export default function Home(){
    return (
        <Layout>
            <div>
                <Title>Coming Soon</Title>
            </div>
        </Layout>
    )
}