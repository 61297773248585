import { useEffect } from "react";
import { query } from "../../library/apolloClient";
import { PROFILE } from "../../graphql";


export default function Callback(){
    useEffect(() => {
        globalThis.userManager.signinRedirectCallback().then(user => {
            query(PROFILE, {}, {}).then(({data}) => {
                if(data){
                    window.localStorage.setItem("profile", JSON.stringify(data.profile))
                    window.location.href = "/"
                }
            }).catch(err => console.log(err))
        }).catch(err => `${err}`)
    }, [])
    return (<div>Redirecting.....</div>)
}