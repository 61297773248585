import Dropdown from "../../../components/common/Dropdown"
import ImageUpload from "../../../components/common/ImageUpload"
import Textbox from "../../../components/common/Textbox"

export interface GeneralProp{
    form: {
        title: string
        email: string
        description: string,
        customSettings: Array<{key: string, value: String}>
    }
    onChange: Function
}

export default function General(props: GeneralProp){
    const onChange = (e) => {
        const _form = {...props.form, customSettings: [...props.form.customSettings]};
        if(["address_google", "logo", "language"].includes(e.currentTarget.name)){
            const fItem = _form.customSettings.find(x => x.key == e.currentTarget.name)
            if(fItem){
                _form.customSettings = _form.customSettings.filter(x => x.key != e.currentTarget.name)
            }
            _form.customSettings.push({key: e.currentTarget.name, value: e.currentTarget.value})
            e = { currentTarget: { name: "customSettings", value: _form.customSettings } }
        }
        props.onChange(e)
    }

    const onUpload = (e) => {
        onChange({ currentTarget: { name: "logo", value: e[0] } })
    }

    return (
        <div className="flex flex-col gap-4 w-full">
            <Textbox className="w-full" name="title" type="text" label="title" value={props.form.title} onChange={onChange} />
            <Textbox className="w-full" name="email" type="email" label="email address" value={props.form.email} onChange={onChange} />
            <Textbox className="w-full" name="address_google" type="text" label="address" value={props.form.customSettings.find(x => x.key == "address_google")?.value || ""} onChange={onChange} />
            <Textbox className="w-full" name="description" type="text" label="description" value={props.form.description} onChange={onChange} />
            <Dropdown 
                name="language" 
                label="language" 
                list={[{title: "English", value: "en"}, {title: "Urdu", value: "ur"}]} 
                value={String(props.form.customSettings.find(x => x.key == "language")?.value || "en")} 
                onChange={onChange} 
            />
            <ImageUpload selected={(props.form.customSettings.filter(x => x.key == "logo").map(y => y.value || "") as Array<string>)} onUpload={onUpload} />
        </div>
    )
}