import gql from 'graphql-tag'

export const FETCH_REGIONS = gql`
query FETCH_REGIONS ($query: QueryParams, $find: regionFilter) {
    GetRegions(query: $query, find: $find) {
    _id
    title
    type
  }
}
`
export const FETCH_ONE_REGION = gql`
query FETCH_ONE_REGION ($id: String!){
  GetOneRegion(id: $id){
    _id
    title
    type
    parent{
      _id
      title
      type
    }
  }
}
`

export const CREATE_REGION = gql`
mutation CREATE_REGION ($regionInput: regionInput!){
  CreateRegion(regionInput: $regionInput){
    _id
    title
    type
  }
}
`

export const UPDATE_REGION = gql`
mutation UPDATE_REGION ($id: String!,$regionInput: regionInput!){
  UpdateRegion(id: $id, regionInput: $regionInput){
    _id
    title
    type
  }
}
`

export const DELETE_REGION = gql`
mutation DELETE_REGION ($id: String!){
  DeleteRegion(id: $id){
    _id
  }
}
`