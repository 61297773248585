import { useParams } from "react-router-dom"
import Layout from "../../layouts"
import { useState } from "react"
import Form from "../../components/container/Form"
import { CREATE_CONTENT, FETCH_ONE_CONTENT, UPDATE_CONTENT } from "../../graphql"
import Button from "../../components/common/Button"
import TabView from "../../components/container/TabView"
import General from "./components/general"
import Translation from "../../components/addons/translation"
import Seo from "../../components/addons/seo"
import { filterLang, onChangeLang } from "../../library/pageHelper"

export default function BlogEditor(){
    const params = useParams()

    const [form, setForm] = useState({ 
        title: "", type: "blog", body: "", status: "draft", lang: "en",
        categories: [], style: []  
    })

    const [categoryType, setcategoryType] = useState("")

    const onData = (data) => {
        if(typeof data !== "undefined"){
            setForm(data["GetOneContent"])
            setcategoryType(data["GetOneContent"].categories.filter(x => x.type !== "feature")[0]["type"])
        }
    }

    const onChange = (e) => {
        const _form = {...form};
        _form[e.currentTarget.name] = e.currentTarget.value;
        setForm(_form);
    }
    

    const onSave = (data) => {
    }

    const onError = (error) => {
    }

    return (
        <Layout>
            <Form 
                id={params.id}
                query={FETCH_ONE_CONTENT}
                onData={onData}  
                variables={{contentInput: form}} 
                submitted={onSave}
                error={onError}
                routeBack="/blog"
                actions={(onSubmit, routeBack) => (
                    <>
                        <Button title="save" icoOnly={true} icon="ico-save w-5 h-5 bg-white" onClick={(e) => onSubmit(e, { create: CREATE_CONTENT, update: UPDATE_CONTENT }, {contentInput: form}, params.id)} />
                        {
                            routeBack && (
                                <Button title="back" icoOnly={true} icon="ico-back w-5 h-5 bg-white" onClick={routeBack} />    
                            )
                        }
                    </>
                )}
            >
                <TabView
                    tabs={[
                        {title: "general", content: (<General form={form} categoryType={categoryType} onChange={onChange} />)},
                        {title: "translation", content: (<Translation form={filterLang("lang_", form.style)} onChange={(e) => onChangeLang(e, "lang_", form, "style", onChange)} />)},
                        {title: "seo", content: (<Seo form={filterLang("seo_", form.style)} onChange={(e) => onChangeLang(e, "seo_", form, "style", onChange)} />)}
                    ]} 
                />
            </Form>
        </Layout>
    )
}