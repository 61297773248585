import Searchtag from "../../../components/common/Searchtag";
import Textbox from "../../../components/common/Textbox";
import { FETCH_MENUS } from "../../../graphql";

export interface GeneralProp{
    form: {title: string, url: string, order: number, parent: any};
    onChange: Function;
}

export default function General(props: GeneralProp){

    const onChange = (e) => {
        props.onChange(e)
    }
    const onParentRemove = (e) => props.onChange({currentTarget: {name: "parent", value: null}})

    return (
        <div className="flex flex-col gap-4">
            <Textbox name="title" type="text" label="title" value={props.form.title} onChange={onChange} />
            <Textbox name="url" type="text" label="url" value={props.form.url} onChange={onChange} />
            <Textbox name="order" type="number" label="order" value={props.form.order} onChange={onChange} />
            <Searchtag 
                name="parent" 
                label="parent"
                className="flex-col flex-col-reverse"
                query={FETCH_MENUS}
                dataKey="GetMenus"
                selected={[{...props.form.parent}]}
                onSelect={(e) => onChange({currentTarget: {name: "parent", value: e}})}
                onRemove={(e) => onParentRemove(e)}  
            />
        </div>
    )
}