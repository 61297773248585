import { useContext, useState } from "react";
import Button from "../components/common/Button";
import Title from "../components/common/Title";
import { UserContext } from "../context/user";
import { Link } from "react-router-dom";

export interface MobilenavProp{
    routes?: Array<{title: string, path: string, icon: string}>
}

export default function Mobilenav(props: MobilenavProp){
    // const [user] = useContext(UserContext);
    const [isOpen, toggle] = useState(false)
    return (
        <div className="flex">
            <Button title="Menu" icoOnly={true} icon="ico-menu w-5 h-5 bg-white" onClick={() => toggle(!isOpen)} />
            {
                isOpen && (
                    <div className="flex flex-col gap-4 absolute top-12 left-0 bg-white w-full p-2 rounded-md shadow-md">
                        <div className="flex absolute right-1 top-0 items-center justify-end">
                            <button className="p-2" onClick={() => toggle(!isOpen)}>X</button>
                        </div>
                        <ul className="flex flex-col gap-2">
                        {
                            props.routes?.map((item, key) => <li className="flex cursor-pointer p-2 border-b-2 border-slate-100" key={key}><i className={item.icon}>&nbsp;</i><Link to={item.path} onClick={() => toggle(!isOpen)}>{item.title}</Link></li>)
                        }
                        </ul>
                    </div>
                )
            }
        </div>
    )
}