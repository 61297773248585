export default {
    "email address": "Email Address",
    "email": "Email",
    "address": "Address",
    "password": "Password",
    "next": "Next",
    "previous": "Prev",
    "id": "Id",
    "title": "Title",
    "name": "Name",
    "action": "Action",
    "general": "General",
    "parent": "Parent",
    "save": "Save",
    "add": "Add",
    "new": "New",
    "edit": "Edit",
    "404": "404",
    "not found": "Not Found",
    "record": "Record",
    "category type": "Category Type",
    "category": "Category",
    "menu": "Menu",
    "product": "Product",
    "room": "Room",
    "reservation": "Reservation",
    "page": "Page",
    "blog": "Blog",
    "order": "Order",
    "setting": "Setting",
    "dashboard": "Dashbaord",
    "logout": "Logout",
    "status": "Status",
    "search": "Search",
    "select": "Select",
    "type": "Type",
    "amenities": "Amenities",
    "rate": "Rate",
    "discount": "Discount",
    "language": "Language",
    "plan": "Plan",
    "floor": "Floor",
    "occupency": "Occupency",
    "url": "Url",
    "configuration": "Configuration",
    "link": "Link",
    "social": "Social",
    "social link": "Social links",
    "feature": "Feature",
    "description": "Description",
    "brand": "Brand",
    "banner": "Banner",
    "media": "Media",
    "remove": "Remove",
    "qty": "Qty",
    "upload": "Upload"
}