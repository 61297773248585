import Searchtag from "../../../components/common/Searchtag";
import Textbox from "../../../components/common/Textbox";
import { FETCH_CATEGORIES, FETCH_CATEGORY_TYPES } from "../../../graphql";

export interface GeneralProp{
    form: {title: string, type: string, parent: any};
    onChange: Function;
}

export default function General(props: GeneralProp){

    const onChange = (e) => {
        props.onChange(e)
    }

    const onCategorytypeRemove = (e) => props.onChange({currentTarget: {name: "type", value: ""}})
    const onParentRemove = (e) => props.onChange({currentTarget: {name: "parent", value: null}})

    return (
        <div className="flex flex-col gap-4">
            <Searchtag 
                name="categoryType" 
                label="category type"
                query={FETCH_CATEGORY_TYPES}
                dataKey="GetCategoryTypes"
                selected={[{title: props.form.type}]}
                onSelect={(e) => onChange({currentTarget: {name: "type", value: String(e.title).toLowerCase()}})}
                onRemove={(e) => onCategorytypeRemove(e)}  
            />
            <Textbox name="title" type="text" label="title" value={props.form.title} onChange={onChange} />
            <Searchtag 
                name="parent" 
                label="parent"
                className="flex-col flex-col-reverse"
                query={FETCH_CATEGORIES}
                titleKey={(row: any) => `${row.title}${row.parent?.title ? ` | ${row.parent?.title}` : ""}`}
                dataKey="GetCategories"
                variables={{find: {type: props.form.type}}}
                selected={[{...props.form.parent}]}
                onSelect={(e) => onChange({currentTarget: {name: "parent", value: e}})}
                onRemove={(e) => onParentRemove(e)}  
            />
        </div>
    )
}