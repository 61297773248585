import Dropdown from "../../../components/common/Dropdown";
import ImageUpload from "../../../components/common/ImageUpload";
import QuilEditor from "../../../components/common/QuilEditor";
import Searchtag from "../../../components/common/Searchtag";
import Textbox from "../../../components/common/Textbox";
import { FETCH_CATEGORIES } from "../../../graphql";

export interface GeneralProp{
    form: {
        title: string, uom: string, lang: string, brand: string,
        rate: number, qty: number,
        discountType: string, discount: number,
        categories: Array<any>, images: Array<any>, 
        productDetail: Array<{key: string, value: string}>
    };
    onChange: Function;
}

export default function General(props: GeneralProp){
    const onChange = (e) => {
        if(["categories", "feature"].includes(e.currentTarget.name)){
            const fCategory = props.form.categories.find(x => x._id == e.currentTarget.value._id)
            if(!fCategory){
                e = {currentTarget: {name: "categories", value: [...props.form.categories, e.currentTarget.value]}}
            }
        }
        if(["description"].includes(e.currentTarget.name)){
            const _productDetails = props.form.productDetail.filter(x => x.key !== e.currentTarget.name)
            e = {currentTarget: {name: "productDetail", value: [..._productDetails, {key: e.currentTarget.name, value: e.currentTarget.value}]}}
        }
        props.onChange(e)
    }

    const onRemove = (e: {currentTarget: { name: string, value: any }}) => {
        switch(e.currentTarget.name){
            case "categories":
            case "feature":
                props.onChange({currentTarget: { name: "categories", value: props.form.categories.filter(x => x._id !== e.currentTarget.value._id) }})
                break;
            case "brand":
                props.onChange({currentTarget: { name: e.currentTarget.name, value: "" }})
                break;
        }
    }
    const onUpload = (e) => {
        props.onChange({currentTarget: {name: "images", value: e}})
    }


    return (
        <div className="flex flex-col md:flex-row md:flex-row-reverse gap-4 w-full">
            <div className="flex flex-col gap-4 md:w-1/3 w-full">
                <Searchtag 
                    name="categories" 
                    label="category"
                    className="flex-col flex-col-reverse"
                    query={FETCH_CATEGORIES}
                    titleKey={(row: any) => `${row.title}${row.parent?.title ? ` | ${row.parent?.title}` : ""}`}
                    dataKey="GetCategories"
                    variables={{find: {type: "product"}}}
                    selected={props.form.categories?.filter(x => x.type === "product")}
                    onSelect={(e) => onChange({currentTarget: {name: "categories", value: e}})}
                    onRemove={(e) => onRemove({ currentTarget: { name: "categories", value: e } })}  
                />
                <Searchtag 
                    name="feature" 
                    label="feature"
                    className="flex-col flex-col-reverse"
                    query={FETCH_CATEGORIES}
                    dataKey="GetCategories"
                    variables={{find: {type: "feature"}}}
                    selected={props.form.categories?.filter(x => x.type === "feature")}
                    onSelect={(e) => onChange({currentTarget: {name: "feature", value: e}})}
                    onRemove={(e) => onRemove({ currentTarget: { name: "feature", value: e } })}  
                />
                <Searchtag 
                    name="brand" 
                    label="brand"
                    className="flex-col flex-col-reverse"
                    query={FETCH_CATEGORIES}
                    dataKey="GetCategories"
                    variables={{find: {type: "brand"}}}
                    selected={[{title: props.form.brand}]}
                    onSelect={(e) => onChange({currentTarget: {name: "brand", value: e.title}})}
                    onRemove={(e) => onRemove({ currentTarget: { name: "brand", value: e } })}  
                />
                <ImageUpload multiple={true} selected={props.form.images} onUpload={onUpload} />
            </div>
            <div className="flex flex-col gap-4 w-full">
                <Textbox name="title" type="text" label="title" value={props.form.title} onChange={onChange} />
                <div className="flex gap-2">
                    <Textbox name="rate" type="text" label="rate" className="w-full" value={props.form.rate} onChange={onChange} />
                    <Textbox name="qty" type="text" label="qty" className="w-full" value={props.form.qty} onChange={onChange} />
                    <Textbox name="uom" type="text" label="uom" className="w-full" value={props.form.uom} onChange={onChange} />
                </div>
                <div className="flex gap-2">
                    <Dropdown label="discount type" list={[{ title: "Flat", value: "flat" },{title: "Percentage", value: "percentage"}]} name="discountType" value={props.form.discountType} onChange={onChange} />
                    <Textbox name="discount" type="text" label="discount" className="w-full" value={props.form.discount} onChange={onChange} />
                </div>
                <QuilEditor tags={true} name="description" value={props.form.productDetail.find(x => x.key == "description")?.value || ""} onChange={(e) => onChange({currentTarget: {name: "description", value: e}})} isContent={(props.form.productDetail.find(x => x.key == "description")?.value || "").length > 0} />
            </div>
        </div>
    )
}