import Title from "../common/Title";

export interface ListViewProp{
    data: Array<any>;
    title?: Function;
    subTitle?: Function;
    actions?: Array<Function>
    className?: string
}

export default function Listview(props: ListViewProp){
    return (
        <div className={`${props.className}`}>
            {
                props.data.map((row, key) => (
                    <div key={key} className="flex flex-col w-full border-2 border-black p-2 rounded-md">
                        <div className="flex justify-end">
                            {props.actions?.length > 0 && props.actions.map((action, key) => action(row, key))}
                        </div>
                        <div className="flex flex-col w-full">
                            {props.title && (<Title className="flex justify-center">{props.title(row, key)}</Title>)}
                            {props.subTitle && (<Title className="text-slate-600 text-sm">{props.subTitle(row, key)}</Title>)}
                        </div>
                    </div>
                ))
            }
        </div>
    )
}