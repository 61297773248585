import { useContext } from "react"
import { ThemeContext } from "../../context/theme"
import { DocumentNode } from "graphql"
import { mutation } from "../../library/apolloClient"
import Button from "./Button";

export interface RemoveDialogProp{
    id: string;
    idKey?: string;
    query: DocumentNode;
    reload?: boolean
}

export default function RemoveDialog(props: RemoveDialogProp){
    const [theme] = useContext(ThemeContext)

    const removeQuery = () => {
        const params: any = {};
        params[props.idKey || "id"] = props.id
        theme.setLoading(true)
        mutation(props.query, params, {})
        .then((data) => {
            theme.setLoading(false)
            if(props.reload){
                window.location.reload()
            }
        })
        .catch((error) => {
            theme.setLoading(false)
        })

    }

    const onRemove = () => {
        theme.setDialog({...theme.dialog, content: "Are you sure you want to remove?", actions: [(key) => (<Button key={key} onClick={removeQuery} title="remove" />)]})
    }
    return (
        <button onClick={onRemove}><i className="ico-trash w-5 h-5 bg-black">&nbsp;</i></button>
    )
}