import { useContext, useEffect, useState } from "react";
import Layout from "../../layouts";
import TabView from "../../components/container/TabView";
import { mutation, query } from "../../library/apolloClient";
import { FETCH_SETTINGS, UPDATE_SETTINGS } from "../../graphql";
import General from "./components/general";
import Button from "../../components/common/Button";
import Email from "./components/email";
import Social from "./components/social";
import { ThemeContext } from "../../context/theme";

import { ApolloError, isApolloError } from '@apollo/client'


export default function Settings(){
    const [settings, setSettings] = useState({title: "", email: "", description: "", customSettings: []})
    const [theme] = useContext(ThemeContext);
    useEffect(() => {
        query(FETCH_SETTINGS, {}, {}).then(({data}) => {
            if(data){
                setSettings(data["GetSettings"] || {...settings})
            }else{
                const _profile = JSON.parse(window.localStorage.getItem("profile") || "{}")
                setSettings({ ...settings, title: _profile.tenant?.name || "", email: _profile.email })
            }
        })
    }, [])
    
    const onChange = (e) => {
        const _settings = {...settings};
        _settings[e.currentTarget.name] = e.currentTarget.value;
        setSettings(_settings);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        theme.setLoading(true)
        mutation(UPDATE_SETTINGS, {settingsInput: settings}, {})
        .then(({data, errors}) => {
            theme.setAlert({...theme.alert, message: "Record has been saved"})
            theme.setLoading(false)
        })
    }
    return (
        <Layout>
            <form onSubmit={onSubmit}>
                <TabView tabs={[{
                    title: "general", content: (<General form={settings} onChange={onChange} />)
                },{
                    title: "email configuration", content: (<Email form={settings.customSettings} onChange={onChange} />)
                },{
                    title: "social link", content: (<Social form={settings.customSettings} onChange={onChange} />)
                }]} />
                <div className="flex justify-center">
                    <Button title="save" className="w-1/2" />
                </div>
            </form>
        </Layout>
    )
}