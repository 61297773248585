import { useParams } from "react-router-dom"
import Layout from "../../layouts"
import { useState } from "react"
import Form from "../../components/container/Form"
import { CREATE_CATEGORY, FETCH_ONE_CATEGORY, UPDATE_CATEGORY } from "../../graphql"
import Button from "../../components/common/Button"
import TabView from "../../components/container/TabView"
import General from "./components/general"
import Translation from "../../components/addons/translation"
import { filterLang, onChangeLang } from "../../library/pageHelper"

export default function CategoryEditor() {
    const params = useParams()
    const [form, setForm] = useState({ type: "", title: "", image: "", lang: [], keywords: [], parent: null })
    
    const onData = (data) => {
        setForm(data["GetOneCategory"])
    }

    const onChange = (e) => {
        const _form = { ...form };
        _form[e.currentTarget.name] = e.currentTarget.value;
        setForm({ ..._form });
    }

    return (
        <Layout>
            <Form
                id={params.id}
                query={FETCH_ONE_CATEGORY}
                onData={onData}
                variables={{ categoryInput: form }}
                routeBack="/category"
                actions={(onSubmit, routeBack) => (
                    <>
                        <Button title="save" icoOnly={true} icon="ico-save w-5 h-5 bg-white" onClick={(e) => onSubmit(e, { create: CREATE_CATEGORY, update: UPDATE_CATEGORY }, {categoryInput: form}, params.id)} />
                        {
                            routeBack && (
                                <Button title="back" icoOnly={true} icon="ico-back w-5 h-5 bg-white" onClick={routeBack} />    
                            )
                        }
                    </>
                )}
            >
                <TabView tabs={[
                    {title: "general", content: (<General form={form} onChange={onChange} />)},
                    {title: "translation", content: (<Translation form={filterLang("lang_", form.lang)} onChange={(e) => onChangeLang(e, "lang_", form, "lang", onChange)} />)}
                ]} />
            </Form>
        </Layout>
    )
}