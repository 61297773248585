import Textbox from "../../../components/common/Textbox";

export interface CustomerProp{
    form: {
        title: string, 
        email: string, 
        phone: string, 
        address: Array<{
            country: string,
            city: string,
            state: string,
            postal: string,
            address: string
        }>, 
        customerDetails: Array<{
            key: string, 
            value: string
        }>
    }
    onChange: Function
}

export default function Customer(props: CustomerProp){
    const onChange = (e) => {
        const _customer = {...props.form}
        if(["cnic", "passport", "passport_expiry", "visa", "visa_expiry"].includes(e.currentTarget.name)){
            const detail = _customer.customerDetails.find(x => x.key == e.currentTarget.name)
            if(detail){
                detail.value = e.currentTarget.value
            }else{
                _customer.customerDetails = [..._customer.customerDetails, { key: e.currentTarget.name, value: e.currentTarget.value }]
            }
        } else if(["country", "city", "state", "postal", "address"].includes(e.currentTarget.name)){
            _customer["address"][0][e.currentTarget.name] = e.currentTarget.value
        } else{
            _customer[e.currentTarget.name] = e.currentTarget.value
        }
        props.onChange({ currentTarget: { name: "customer", value: _customer } })
    }


    return (
        <div className="flex flex-col gap-4">
            <Textbox type="text" className="w-full" name="title" label="Name" value={props.form.title} onChange={onChange} />
            <div className="flex flex-col md:flex-row gap-2">
                <Textbox type="text" className="w-full" name="email" label="Email address" value={props.form.email} onChange={onChange} />
                <Textbox type="text" className="w-full" name="phone" label="Phone Number" value={props.form.phone} onChange={onChange} />
                <Textbox type="text" className="w-full" name="cnic" label="CNIC Number" value={props.form.customerDetails.find(x => x.key == "cnic")?.value} onChange={onChange} />
            </div>
            <div className="flex flex-col md:flex-row gap-2">
                <Textbox type="text" className="w-full" name="passport" label="Passport Number" value={props.form.customerDetails.find(x => x.key == "passport")?.value} onChange={onChange} />
                <Textbox type="date" className="w-full" name="passport_expiry" label="Passport Expiry" value={props.form.customerDetails.find(x => x.key == "passport_expiry")?.value} onChange={onChange} />
                <Textbox type="text" className="w-full" name="visa" label="Visa Number" value={props.form.customerDetails.find(x => x.key == "visa")?.value} onChange={onChange} />
                <Textbox type="date" className="w-full" name="visa_expiry" label="Visa Expiry" value={props.form.customerDetails.find(x => x.key == "visa_expiry")?.value} onChange={onChange} />
            </div>
            <div className="flex flex-col md:flex-row gap-2">
                <Textbox type="text" className="w-full" name="country" label="Country" value={props.form.address[0].country} onChange={onChange} />
                <Textbox type="text" className="w-full" name="city" label="City" value={props.form.address[0].city} onChange={onChange} />
                <Textbox type="text" className="w-full" name="state" label="state" value={props.form.address[0].state} onChange={onChange} />
                <Textbox type="text" className="w-full" name="postal" label="Postal Number" value={props.form.address[0].postal} onChange={onChange} />
            </div>
            <Textbox type="text" className="w-full" name="address" label="Address" value={props.form.address[0].address} onChange={onChange} />
        </div>
    );
}