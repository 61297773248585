import { LANGUAGES, LIMIT } from "./constant";
export interface QueryParams{
    limit?: number;
    page?: number; 
    sort?: string;
    q?: string;
    find?: string
}

export const makePageQuery = (searchParams: URLSearchParams): QueryParams => ({ 
    limit: Number(searchParams.get("limit") || LIMIT), 
    page: Number(searchParams.get("page") || 1), 
    q: searchParams.get("q") || "",
    find: JSON.stringify(searchParams.get("find") || "{}") || "",
})

export const makeFilter = (searchParams: URLSearchParams) => searchParams.get("find") || "{}";

export const changePage = (flag: string, queryParams: URLSearchParams, find: string = "{}") => {
    const __searchParams = new URLSearchParams()
    const searchParams = makePageQuery(queryParams);
    const filter = makeFilter(queryParams)
    find = find || searchParams?.find || "{}"
    
    switch(flag){
        case "next":
            __searchParams.set("limit", (searchParams?.limit || LIMIT)?.toString())
            __searchParams.set("page", (searchParams?.page + 1)?.toString())
            __searchParams.set("q", searchParams?.q)
            __searchParams.set("find", Object.keys(JSON.parse(find)).length > 0 ? find : filter)
            break;
        case "prev":
            __searchParams.set("limit", (searchParams?.limit || LIMIT)?.toString())
            __searchParams.set("page", (searchParams?.page - 1)?.toString())
            __searchParams.set("q", searchParams?.q)
            __searchParams.set("find", Object.keys(JSON.parse(find)).length > 0 ? find : filter)
            break;
        case "clearFilter":
            __searchParams.set("limit", (LIMIT)?.toString())
            __searchParams.set("page", (1)?.toString())
            __searchParams.set("q", "")
            __searchParams.set("find", "{}")
            break;
        default:
            __searchParams.set("limit", (LIMIT)?.toString())
            __searchParams.set("page", (1)?.toString())
            __searchParams.set("q", searchParams?.q || "")
            __searchParams.set("find", Object.keys(JSON.parse(find)).length > 0 ? find : filter)
            break;
    }
    return __searchParams
}

export const hasPrev = (searchParams: URLSearchParams) => {
    return Number(searchParams.get("page") || 1) <= 1 && true; 
}

export const hasNext = (limit: number, count: number) => {
    return limit < count && true
}

export const filterLang = (pattren: string, data: any): Array<{title: string, description: string, language: string, keywords?: string}> => {
    const lang = data.filter(x => x.key.startsWith(pattren));
    return LANGUAGES.map(language => ({ 
        language: language.code,
        title: lang.find(x => x.key == `${pattren}${language.code}_title`)?.value || "",
        keywords:  lang.find(x => x.key == `${pattren}${language.code}_keywords`)?.value || "",
        description:  lang.find(x => x.key == `${pattren}${language.code}_description`)?.value || "",
    })) || []
}

export const onChangeLang = (e: {currentTarget: { name: string, value: any }}, pattren: string, form: any,datakey: string, onChange: Function) => {
    if(e.currentTarget.name.startsWith(pattren)){
        const details = [...form[datakey]].filter(x => x.key !== e.currentTarget.name)
        e = { currentTarget:{name: datakey, value: [...details, {key: e.currentTarget.name, value: e.currentTarget.value}]} }
    }
    onChange(e)
}
