import { Link } from "react-router-dom";
import Datatable, { Datasource } from "../../components/container/DataTable";
import Layout from "../../layouts";
import { DELETE_MENU, FETCH_MENUS } from "../../graphql";
import RemoveDialog from "../../components/common/RemoveDialog";
import { __t } from "../../library/textHelper";

const DATASOURCE: Datasource = {
    columns: [
        { title: __t("title"), field: "title" },
        { title: __t("url"), field: "url" }
    ],
    title: (row, key) => row["title"],
    subTitle: (row, key) => row["url"],
    actions: [
        (row, key) => (<Link key={key} to={`${row._id}`}><i className="ico-pencil-square w-5 h-5 bg-black">&nbsp;</i></Link>),
        (row, key) => (<RemoveDialog key={key} id={row._id} reload={true} query={DELETE_MENU} />),
    ]
}

export default function Menu(){
    return (
        <Layout>
            <Datatable query={FETCH_MENUS} dataKey="GetMenus" newRoute="new" datasource={DATASOURCE} />
        </Layout>
    )
}