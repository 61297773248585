import Dropdown from "../../../components/common/Dropdown";
import ImageUpload from "../../../components/common/ImageUpload";
import QuilEditor from "../../../components/common/QuilEditor";
import Searchtag from "../../../components/common/Searchtag";
import Textbox from "../../../components/common/Textbox";
import { FETCH_CATEGORIES, FETCH_PRODUCTS } from "../../../graphql";

export interface GeneralProp{
    form: {
        title: string, body: string, images: Array<String>,
        rate: number, discountType: string, discount: number,
        categories: Array<any>, products: Array<any>, 
        roomDetails: Array<{key: string, value: string}>
    };
    onChange: Function;
}

export default function General(props: GeneralProp){
    const onChange = (e) => {
        if(["categories", "feature"].includes(e.currentTarget.name)){
            const fCategory = props.form.categories.find(x => x._id == e.currentTarget.value._id)
            if(!fCategory){
                e = {currentTarget: {...e.currentTarget, value: [...props.form.categories, e.currentTarget.value]}}
            }
        }
        if(["products"].includes(e.currentTarget.name)){
            const fProduct = props.form.products.find(x => x._id == e.currentTarget.value._id)
            if(!fProduct){
                e = {currentTarget: {...e.currentTarget, value: [...props.form.products, e.currentTarget.value]}}
            }
        }
        if(["rate", "discount"].includes(e.currentTarget.name)){
            e = {currentTarget: { name: e.currentTarget.name, value: Number(e.currentTarget.value) }}
        }
        props.onChange(e)
    }
    const onRemove = (e: {currentTarget: { name: string, value: any }}) => {
        switch(e.currentTarget.name){
            case "categories":
                props.onChange({currentTarget: { name: e.currentTarget.name, value: props.form.categories.filter(x => x._id !== e.currentTarget.value._id) }})
                break;
            case "amenities":
                props.onChange({currentTarget: { name: e.currentTarget.name, value: props.form.categories.filter(x => x._id !== e.currentTarget.value._id) }})
                break;
            case "products":
                props.onChange({currentTarget: { name: e.currentTarget.name, value: props.form.products.filter(x => x._id !== e.currentTarget.value._id) }})
                break;
        }
    }

    const onUpload = (e) => {
        props.onChange({currentTarget: {name: "images", value: e}})
    }

    return (
        <div className="flex flex-col md:flex-row md:flex-row-reverse gap-4 w-full">
            <div className="flex flex-col gap-4 md:w-1/3 w-full">
                <Searchtag 
                    name="categories" 
                    label="category"
                    className="flex-col flex-col-reverse"
                    query={FETCH_CATEGORIES}
                    titleKey={(row: any) => `${row.title}${row.parent?.title ? ` | ${row.parent?.title}` : ""}`}
                    dataKey="GetCategories"
                    variables={{find: {type: "room"}}}
                    selected={props.form.categories?.filter(x => x.type === "room")}
                    onSelect={(e) => onChange({currentTarget: {name: "categories", value: e}})}
                    onRemove={(e) => onRemove({ currentTarget: { name: "categories", value: e } })}  
                />
                <Searchtag 
                    name="products" 
                    label="product"
                    className="flex-col flex-col-reverse"
                    query={FETCH_PRODUCTS}
                    dataKey="GetProducts"
                    selected={props.form.products}
                    onSelect={(e) => onChange({currentTarget: {name: "products", value: e}})}
                    onRemove={(e) => onRemove({ currentTarget: { name: "products", value: e } })}  
                />
                <Searchtag 
                    name="amenities" 
                    label="amenities"
                    className="flex-col flex-col-reverse"
                    query={FETCH_CATEGORIES}
                    dataKey="GetCategories"
                    variables={{find: {type: "amenities"}}}
                    selected={props.form.categories?.filter(x => x.type === "amenities")}
                    onSelect={(e) => onChange({currentTarget: {name: "amenities", value: e.title}})}
                    onRemove={(e) => onRemove({ currentTarget: { name: "amenities", value: e } })}  
                />
                <ImageUpload multiple={true} onUpload={onUpload} />
            </div>
            <div className="flex flex-col gap-4 w-full">
                <Textbox name="title" type="text" label="title" value={props.form.title} onChange={onChange} />
                <div className="flex gap-2">
                    <Textbox name="rate" type="number" label="rate" className="w-full" value={props.form.rate} onChange={onChange} />
                    <Dropdown label="discount type" list={[{ title: "Flat", value: "flat" }]} name="discountType" value={props.form.discountType} onChange={onChange} />
                    <Textbox name="discount" type="number" label="discount" className="w-full" value={props.form.discount} onChange={onChange} />
                </div>
                <QuilEditor name="body" value={props.form.body || ""} onChange={(e) => onChange({currentTarget: {name: "body", value: e}})} isContent={String(props.form.body || "").length > 0}/>
            </div>  
        </div>
    )
}