import { useContext } from "react";
import Button from "../components/common/Button";
import Title from "../components/common/Title";
import { __t } from "../library/textHelper";
import Mobilenav from "./MobileNav";
import Profile from "./Profile";
import { ThemeContext } from "../context/theme";

export interface TopProp{
    routes?: Array<any>
}

const logout = async () => {
    window.localStorage.removeItem("profile")
    window.localStorage.removeItem("language")
    window.localStorage.removeItem("view")
    window.location.href = "/"
    // const issuer_endpoint = await globalThis.userManager.metadataService.getIssuer();
    // globalThis.userManager.settings.metadata.end_session_endpoint = `${issuer_endpoint}oidc/logout`;
    // await globalThis.userManager.signoutRedirect({ post_logout_redirect_uri: `${window.location.origin}/logout` });
}

const pageName = () => {
    const path = window.location.pathname.split("/");
    return path.length > 2 ? `${path[2] != "new" ? __t("edit") : __t("new")} ${__t(path[1].replace("-", " "))}` : __t(path[1].replace("-", " "), true);
}

export default function(props: TopProp){
    const [theme] = useContext(ThemeContext)

    return (
        <div className="flex gap-2 sticky top-0 bg-white p-2 border-2 rounded-md w-full z-50">
            <div className="flex gap-2 items-center w-full">
                <Mobilenav routes={props.routes}/>
                <Title className="capitalize">{pageName().length > 2 ? pageName() : __t("dashboard")}</Title>
                {theme.formButton}
            </div>
            <div className="flex gap-2 justify-end items-center w-full">
                <Profile />
                <Button icon="ico-arrow-right-end-rectangle w-5 h-5 bg-white" title={__t("logout")} onClick={logout} />
            </div>
        </div>
    )
}