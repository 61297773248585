export default {
    "email address": "ای میل اڈریس",
    "email": "ای میل",
    "address": "پتہ",
    "password": "پاس ورڈ",
    "next": "اگلے",
    "previous": "پچھلے",
    "id": "آئی ڈی",
    "title": "عنوان",
    "name": "نام",
    "action": "عمل",
    "general": "جنرل",
    "parent": "والدین",
    "save": "محفوظ کریں",
    "add": "شامل کریں",
    "new": "نئی",
    "edit": "ترمیم",
    "404": "404",
    "not found": "نہیں ملا",
    "record": "ریکارڈ",
    "category type": "زمرہ کی اقسام",
    "category": "اقسام",
    "menu": "مینو",
    "product": "مصنوعات",
    "room": "کمرے",
    "reservation": "تحفظات",
    "page": "صفحات",
    "blog": "بلاگز",
    "order": "سیلز",
    "setting": "ترتیبات",
    "dashboard": "ڈیش بورڈ",
    "logout": "باہر نکلیں",
    "status": "حالت",
    "search": "تلاش کریں",
    "select": "منتخب کریں",
    "type": "انداز",
    "amenities": "سہولیات",
    "rate": "قیمت",
    "discount": "رعایت",
    "language": "زبان",
    "translation": "ترجمہ",
    "plan": "منصوبہ",
    "floor": "فرش",
    "occupency": "قبضے",
    "url": "یو آر ایل",
    "configuration": "ترتیب",
    "link": "لنک",
    "social": "سماجی",
    "social link": "سماجی روابط",
    "feature": "خصوصیت",
    "description": "تفصیل",
    "brand": "برانڈز",
    "banner": "بینرز",
    "qty": "مقدار",
    "remove": "مٹا دو",
    "media": "میڈیا",
    "upload": "اپ لوڈ کریں",
    "open": "کھولیں",
    "editor": "ایڈیٹر",
    "close": "بند کریں"
}