import { Link } from "react-router-dom";
import Datatable, { Datasource } from "../../components/container/DataTable";
import Layout from "../../layouts";
import { DELETE_BANNER, FETCH_BANNERS } from "../../graphql";
import RemoveDialog from "../../components/common/RemoveDialog";
import { __t } from "../../library/textHelper";

const DATASOURCE: Datasource = {
    columns: [
        { title: __t("id"), field: "_id" },
        { title: __t("title"), field: "title" },
    ],
    title: (row) => row["title"],
    actions: [
        (row, key) => (<Link key={key} to={`${row._id}`}><i className="ico-pencil-square w-5 h-5 bg-black">&nbsp;</i></Link>),
        (row, key) => (<RemoveDialog key={key} id={row._id} reload={true} query={DELETE_BANNER} />),
    ]
}

export default function Banner(){
    return (
        <Layout>
            <Datatable query={FETCH_BANNERS} variables={{find: {type: "page"}}} dataKey="GetBanners" newRoute="new" datasource={DATASOURCE} />
        </Layout>
    )
}