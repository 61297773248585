import { __t } from "../../library/textHelper";

export interface TextboxProp{
    type: string
    label: string
    name: string
    disabled?: boolean;
    value: any
    onChange: any
    onFocus?: any;
    onBlur?: any;
    className?: string
    error?: string
} 

export default function Textbox(props: TextboxProp){
    // return (
    //     <input
    //         autoComplete="off" 
    //         type={props.type} 
    //         name={props.name}
    //         placeholder={props.label}
    //         onChange={props.onChange} 
    //         className={`outline-none p-2 border-b-2 border-black h-8 border-2 border-black ${props.className}`}
    //         value={props.value} 
    //     />
    // )
    return (
        <div className={`flex flex-col relative ${props.className}`}>
            {
                props.type == "textarea" ? (
                    <textarea
                        name={props.name}
                        disabled={props.disabled}
                        onChange={props.onChange} 
                        placeholder={props.label}
                        rows={5}
                        value={props.value || ""}
                        className={`peer placeholder:text-transparent outline-none p-2 w-full border-b-2 border-black resize-none`}
                    />
                ) : (
                    <input
                        autoComplete="off" 
                        type={props.type} 
                        name={props.name}
                        disabled={props.disabled}
                        onChange={props.onChange}
                        // onFocus={props.onFocus}
                        onFocusCapture={props.onFocus} 
                        onBlurCapture={props.onBlur}
                        placeholder={props.label}
                        className={`peer placeholder:text-transparent outline-none p-2 py-4 h-10 w-full border-b-2 border-black`}
                        value={props.value} 
                    />
                )
            }
            <label className="absolute left-0 top-[20%] ml-1 -translate-y-4 px-1 bg-transparent text-sm duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-500 peer-focus:ml-1 peer-focus:-translate-y-4 peer-focus:px-1 peer-focus:text-sm">{__t(props.label)}</label>
            {
                props.error && (
                    <label className="flex text-sm text-red-600">{props.error}</label>
                )
            }
        </div>
    )
}