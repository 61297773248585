import { Link } from "react-router-dom";
import Datatable, { Datasource } from "../../components/container/DataTable";
import Layout from "../../layouts";
import { DELETE_MEDIA, FETCH_ALL_MEDIA } from "../../graphql";
import RemoveDialog from "../../components/common/RemoveDialog";
import { __t } from "../../library/textHelper";
import { API_ENDPOINT } from "../../library/constant";

const DATASOURCE: Datasource = {
    columns: [
        { title: __t("title"), field: "title", template: (row) =>  (<img className="object-scale-down w-48 h-48" src={`https://api.metastore.cloud/storage/${row["url"]}`} />)},
        { title: __t("url"), field: "url" },
    ],
    title: (row, key) => (<img className="object-scale-down w-48 h-48" src={`${API_ENDPOINT}/storage/${row["url"]}`} />),
    subTitle: (row, key) => row["url"],
    actions: [
        // (row, key) => (<Link key={key} to={`${row._id}`}><i className="ico-pencil-square w-5 h-5 bg-black">&nbsp;</i></Link>),
        (row, key) => (
            <div className="flex gap-2">
                <RemoveDialog key={key} idKey="key" id={row.url} reload={true} query={DELETE_MEDIA} />
                <a target="_blank" key={key} href={`${API_ENDPOINT}/storage/${row["url"]}`}><i className="ico-link w-5 h-5 bg-black">&nbsp;</i></a>
            </div>
        ),
    ]
}

export default function Media(){
    return (
        <Layout>
            <Datatable query={FETCH_ALL_MEDIA} dataKey="GetAllMedia" newRoute="new" datasource={DATASOURCE} view="grid" />
        </Layout>
    )
}