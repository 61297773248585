import { __t } from "../../library/textHelper"
import Textbox from "./Textbox"

export interface SearchBoxProp{
    text: string, 
    onChange: Function,
    children?: any
    onClear: any,
    className?: string
}

export default function SearchBox(props: SearchBoxProp = {text: "", onChange: (e) => {}, onClear: () => {}, className: ""}){    
    return (
        <div className={`relative ${props.className}`}>
            <div className="flex rounded-full w-full border-2 items-center gap-2 bg-white px-2">
                <i className="ico ico-search bg-black h-5 w-5"></i>
                <Textbox type="text" label="search" name="search" onChange={props.onChange} value={props.text} className="w-full border-none" />
                <button className="flex items-center" onClick={props.onClear}><i className="ico-times bg-black h-5 w-5"></i></button>
            </div>
            <div className="absolute z-10 w-full">{props.children}</div>
        </div>
    )
}