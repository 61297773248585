import THNAKYOU from '../../assets/thanks_you.svg'
import LogoImage from '../../assets/logo.svg'
import Title from '../../components/common/Title'


export default function Thankyou(){
    return (
        <div className="flex h-screen">
            <div className="flex flex-col justify-start md:justify-center gap-2 w-full md:w-1/2 p-5 md:p-16 bg-white md:border-slate md:border-r-2">
                <img src={LogoImage} alt="Logo" className='w-full mb-5' />
                <p className='w-full text-center font-semibold'>We have sent you an email for verification!</p>
                <hr />
                <p><b>Welcome to Shopbase!</b> We’re thrilled to have you join our community.</p>
                <b>Get Started</b>
                <p>To help you hit the ground running, here are some resources you might find useful:</p>
                <ul className='flex flex-col gap-2'>
                    <li><b>Getting Started Guide:</b> Learn how to make the most of our features.</li>
                    <li><b>FAQs:</b> Find answers to common questions.</li>
                    <li><b>Support:</b> Need help? Our support team is here for you.</li>
                    <li><b>Community Forum:</b> Connect with other users and share your experiences.</li>
                </ul>
                <p>Feel free to explore and let us know if you have any questions or feedback. You can reach out to us anytime at support@metastore.cloud.</p>
                <p>Thank you for choosing Shopbase. We look forward to helping you [achieve a specific goal or benefit related to your service/product].</p>
            </div>
            <div className="hidden md:flex flex-col bg-[#eff1f4] w-full">
                <img src={THNAKYOU} alt="Signup Image" className='h-full w-full' />
            </div>
        </div>
    )
}