import { useParams } from "react-router-dom"
import Layout from "../../layouts"
import { useState } from "react"
import Form from "../../components/container/Form"
import { CREATE_PRODUCT, FETCH_ONE_PRODUCT, UPDATE_PRODUCT } from "../../graphql"
import Button from "../../components/common/Button"
import TabView from "../../components/container/TabView"
import General from "./components/general"
import Translation from "../../components/addons/translation"
import Seo from "../../components/addons/seo"
import { filterLang, onChangeLang } from "../../library/pageHelper"

export default function ProductEditor() {
    const params = useParams()
    const [form, setForm] = useState({
        title: "", uom: "", lang: "", brand: "",
        rate: 0, qty: 0,
        discountType: "", discount: 0,
        categories: [], images: [], 
        productDetail: [
          { key: "description", value: "" }
        ]
    })
    
    const onData = (data) => {
        Object.assign(form, { ...data["GetOneProduct"] })
        setForm({...data["GetOneProduct"]})
    }

    const onChange = (e) => {
        const _form = { ...form };
        if(["rate", "qty", "discount"].includes(e.currentTarget.name)){
            _form[e.currentTarget.name] = parseFloat(e.currentTarget.value)
        }else{
            _form[e.currentTarget.name] = e.currentTarget.value;
        }
        setForm({ ..._form });
    }

    return (
        <Layout>
            <Form
                id={params.id}
                query={FETCH_ONE_PRODUCT}
                onData={onData}
                variables={{ productInput: form }}
                routeBack="/product"
                actions={(onSubmit, routeBack) => (
                    <>
                        <Button title="save" icoOnly={true} icon="ico-save w-5 h-5 bg-white" onClick={(e) => onSubmit(e, { create: CREATE_PRODUCT, update: UPDATE_PRODUCT }, {productInput: form}, params.id)} />
                        {
                            routeBack && (
                                <Button title="back" icoOnly={true} icon="ico-back w-5 h-5 bg-white" onClick={routeBack} />    
                            )
                        }
                    </>
                )}
            >
                <TabView
                    tabs={[
                        {title: "general", content: (<General form={form} onChange={onChange} />)},
                        // {title: "Variants", content: (<div>Coming Soon.</div>)},
                        {title: "translation", content: (<Translation form={filterLang("lang_", form.productDetail)} onChange={(e) => onChangeLang(e, "lang_", form, "productDetail", onChange)} />)},
                        {title: "SEO", content: (<Seo form={filterLang("seo_", form.productDetail)} onChange={(e) => onChangeLang(e, "seo_", form, "productDetail", onChange)} />)},
                    ]} 
                />
            </Form>
        </Layout>
    )
}