import gql from 'graphql-tag'

export const FETCH_CATEGORY_TYPES = gql`
query FETCH_CATEGORY_TYPES ($query: QueryParams, $find: categoryTypeFilter) {
    GetCategoryTypes(query: $query, find: $find) {
    _id
    title
    include
  }
}
`

export const FETCH_ONE_CATEGORY_TYPE = gql`
query FETCH_ONE_CATEGORY_TYPE ($id: String!){
  GetOneCategoryType(id: $id){
    _id
    title
    include
  }
}
`

export const CREATE_CATEGORY_TYPE = gql`
mutation CREATE_CATEGORY_TYPE ($categoryTypeInput: categoryTypeInput!){
  CreateCategoryType(categoryTypeInput: $categoryTypeInput){
    _id
    title
  }
}
`

export const UPDATE_CATEGORY_TYPE = gql`
mutation UPDATE_CATEGORY_TYPE ($id: String!,$categoryTypeInput: categoryTypeInput!){
  UpdateCategoryType(id: $id, categoryTypeInput: $categoryTypeInput){
    _id
    title
  }
}
`

export const DELETE_CATEGORY_TYPE = gql`
mutation DELETE_CATEGORY_TYPE ($id: String!){
  DeleteCategoryType(id: $id){
    _id
  }
}
`

export const FETCH_CATEGORIES = gql`
query FETCH_CATEGORIES ($query: QueryParams, $find: categoryFilter) {
    GetCategories(query: $query, find: $find) {
    _id
    title
    slug
    type
    image
    keywords
    parent{
      _id
      title
    }
  }
}
`

export const FETCH_ONE_CATEGORY = gql`
query FETCH_ONE_CATEGORY ($id: String!){
  GetOneCategory(id: $id){
    _id
    title
    slug
    type
    image
    keywords
    lang {
      key
      value
    }
    parent{
      _id
      title
    }
  }
}
`

export const CREATE_CATEGORY = gql`
mutation CREATE_CATEGORY ($categoryInput: categoryInput!){
  CreateCategory(categoryInput: $categoryInput){
    _id
    title
    type
  }
}
`

export const UPDATE_CATEGORY = gql`
mutation UPDATE_CATEGORY ($id: String!,$categoryInput: categoryInput!){
  UpdateCategory(id: $id, categoryInput: $categoryInput){
    _id
    title
    type
  }
}
`

export const DELETE_CATEGORY = gql`
mutation DELETE_CATEGORY ($id: String!){
  DeleteCategory(id: $id){
    _id
  }
}
`