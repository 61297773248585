import Dropdown from "../../../components/common/Dropdown";
import ImageUpload from "../../../components/common/ImageUpload";
import Textbox from "../../../components/common/Textbox";

export interface GeneralProp{
    form: { title: string, type: string, image: string };
    onChange: Function;
}

export default function General(props: GeneralProp){
    const onChange = (e) => {
        props.onChange(e)
    }

    const onUpload = (e) => {
        props.onChange({ currentTarget: { name: "image", value: e[0] } })
    }

    return (
        <div className="flex flex-col gap-4 w-full">
            <Dropdown name="type" label="type" list={[{title: "Banner", value: "banner"}, {title: "AD", value: "ad"}, {title: "Newsletter", value: "newsletter"}]} value={props.form.type} onChange={onChange} />
            <Textbox type="text" name="title" label="title" value={props.form.title} onChange={onChange} />
            <ImageUpload onUpload={onUpload} />
        </div>
    )
}