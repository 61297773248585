import { useParams } from "react-router-dom"
import Layout from "../../layouts"
import { useState } from "react"
import Form from "../../components/container/Form"
import { CREATE_ROOM, FETCH_ONE_ROOM, UPDATE_ROOM } from "../../graphql"
import Button from "../../components/common/Button"
import TabView from "../../components/container/TabView"
import General from "./components/general"
import Translation from "../../components/addons/translation"
import Seo from "../../components/addons/seo"
import Occupency from "./components/occupency"
import FloorPlan from "./components/floorplan"
import { filterLang, onChangeLang } from "../../library/pageHelper"

export default function RoomEditor() {
    const params = useParams()
    const [form, setForm] = useState({
        title: "", body: "", rate: 0, discountType: "", discount: 0,
        categories: [], products: [],  images: [],
        occupency: {baseAdult: 0, baseChildren: 0, maxAdult: 0, maxChildren: 0},
        floorPlan: [],roomDetails: []
    })
    
    const onData = (data) => {
        setForm(data["GetOneRoom"])
    }

    const onChange = (e) => {
        const _form = { ...form };
        _form[e.currentTarget.name] = e.currentTarget.value;
        setForm({ ..._form });
    }

    return (
        <Layout>
            <Form
                id={params.id}
                query={FETCH_ONE_ROOM}
                onData={onData}
                variables={{ roomInput: form }}
                routeBack="/room"
                actions={(onSubmit, routeBack) => (
                    <>
                        <Button title="save" icoOnly={true} icon="ico-save w-5 h-5 bg-white" onClick={(e) => onSubmit(e, { create: CREATE_ROOM, update: UPDATE_ROOM }, {roomInput: form}, params.id)} />
                        {
                            routeBack && (
                                <Button title="back" icoOnly={true} icon="ico-back w-5 h-5 bg-white" onClick={routeBack} />    
                            )
                        }
                    </>
                )}
            >
                <TabView
                    tabs={[
                        {title: "general", content: (<General form={form} onChange={onChange} />)},
                        {title: "occupency", content: (<Occupency form={form.occupency} onChange={onChange} />)},
                        {title: "floor plan", content: (<FloorPlan form={form.floorPlan} onChange={onChange} />)},
                        {title: "translation", content: (<Translation form={filterLang("lang_", form.roomDetails)} onChange={(e) => onChangeLang(e, "lang_", form, "roomDetails", onChange)} />)},
                        {title: "SEO", content: (<Seo form={filterLang("seo_", form.roomDetails)} onChange={(e) => onChangeLang(e, "seo_", form, "roomDetails", onChange)} />)},
                    ]} 
                />
            </Form>
        </Layout>
    )
}