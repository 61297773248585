import gql from 'graphql-tag'


export const INVITE_USER = gql`
mutation INVITE_USER($userToken: String!){
  InviteUser(userToken: $userToken)
}
`


export const FETCH_USERS = gql`
query FETCH_USERS ($query: QueryParams){
    GetUsers(query: $query){
    _id
    name
    email
    role
    is_active
  }
}
`

export const FETCH_CUSTOMERS = gql`
query FETCH_CUSTOMERS ($query: QueryParams){
  GetCustomers(query: $query){
    _id
    name
    role
  }
}
`


export const FETCH_ONE_USER = gql`
query FETCH_ONE_USER ($id: String!){
  GetOneUser(id: $id){
    _id
    name
    email
    username
    role
    is_active
  }
}
`

export const LOGIN = gql`
mutation login ($authInput: authInput!){
  loginUser(authInput: $authInput){
    token
    profile {
      name
      email
      role
      UserDetails{
				key
				value
			}
      tenant{
        _id
        name
        type
      }
    }
  }
}
`

export const PROFILE = gql`
query profile {
    profile {
        name
        email
        tenant{
          _id
        }
        role
    }
}
`

export const REGISTER_USER = gql`
mutation REGISTER_USER ($authInput: authInput!){
  RegisterUser(authInput: $authInput){
    _id
  }
}
`

export const UPDATE_USER = gql`
mutation UPDATE_USER ($id: String!,$authInput: authInput!){
  UpdateUser(id: $id, authInput: $authInput){
    _id
  }
}
`

export const DELETE_USER = gql`
mutation DELETE_USER ($id: String!){
  DeleteUser(id: $id){
    _id
  }
}
`

export const CHECK_LOGIN = gql`
query CHECK_LOGIN{
  CheckLogin
}
`