import { ReactNode, useState } from "react"
import Dropdown from "../common/Dropdown";
import { __t } from "../../library/textHelper";

export interface TabViewProp{
    tabs: Array<{ title: string, ishide?: boolean, content: ReactNode }>
    headerStyle?: string
    actions?: ReactNode
    isAlive?: boolean
    className?: string
}

export default function TabView(props: TabViewProp){
    const [active, setActive] = useState(0);
    return (
        <div className={`relative flex flex-col gap-2 mb-8 w-full ${props.className}`}>
            <Dropdown className={`flex md:hidden ${props.headerStyle}`} itemClassName="capitalize" name="tabs" label="tab" list={props.tabs.map((x, key) => ({ title: x.title, value: String(key) }))} value={String(active)} onChange={(e) => setActive(Number(e.currentTarget.value))}/>
            <ul className={`md:flex bg-white hidden w-full border-b-2 border-black shadow-md ${props.headerStyle}`}>
                {
                    props.tabs.map((tab, key) => (
                        <li 
                            key={key} 
                            onClick={() => setActive(key)}
                            className={`flex items-center px-2 ${key == active ? 'bg-black text-white text-center p-2 rounded-t-md font-bold' : 'rounded-t-md hover:bg-gray-200 cursor-pointer'}`}
                        >{__t(tab.title)}</li>
                    ))
                }
            </ul>
            {props.actions}
            {
                props.isAlive ? props.tabs.map((tab, index) => (
                    <div key={index} className={`${active !== index && "hidden"} py-2`}>{tab.content}</div>
                )) : (
                    <div className={`py-8`}>{props.tabs[active].content}</div>
                )
            }
        </div>
    )
}