export const API_ENDPOINT = "https://api.metastore.cloud"
// export const API_ENDPOINT = "http://localhost:4000"
export const AUTHORITY_URL = 'https://netsolonline.us.auth0.com'
export const CLIENT_ID = 'D1qu9mWk8uc49DEOIq0lyrrXdhLcndVl'
export const LIMIT = 50;

export enum BusinessType {
    MINISHOP = "mini-shop",
    BLOGGER = "blogger"
};

export const LANGUAGES = [
    {title: "English", code: "en"},
    {title: "French", code: "fr"},
    {title: "German", code: "de"},
    {title: "Chinese", code: "cn"},
    {title: "Spanish", code: "es"},
    {title: "Dutch", code: "nl"},
    {title: "Italian", code: "it"},
    {title: "Japanese", code: "jp"},
    {title: "Arabic", code: "ar"},
    {title: "Urdu", code: "ur"}
].sort();
