import { useNavigation, useParams } from 'react-router-dom'
import { ReactNode, useContext, useEffect, useState } from "react";
import Top from "./Top";
import { __t } from "../library/textHelper";
import { BusinessType } from "../library/constant";
import { ThemeContext } from '../context/theme';

export interface LayoutProp{
    children: ReactNode
}
const ROUTES: Array<{title: string, path: string, icon: string}> = [
    {title: __t("dashboard"), path: "/", icon: "ico-dashboard bg-black w-5 h-5"},
    {title: __t("category type", true), path: "/category-type", icon: "ico-entity bg-black w-5 h-5"},
    {title: __t("category", true), path: "/category", icon: "ico-entity bg-black w-5 h-5"},
    {title: __t("banner", true), path: "/banner", icon: "ico-entity bg-black w-5 h-5"},
    {title: __t("menu", true), path: "/menu", icon: "ico-entity bg-black w-5 h-5"},
    {title: __t("product", true), path: "/product", icon: "ico-entity bg-black w-5 h-5"},
    {title: __t("room", true), path: "/room", icon: "ico-entity bg-black w-5 h-5"},
    {title: __t("reservation", true), path: "/reservation", icon: "ico-entity bg-black w-5 h-5"},
    {title: __t("page", true), path: "/page", icon: "ico-entity bg-black w-5 h-5"},
    {title: __t("blog", true), path: "/blog", icon: "ico-entity bg-black w-5 h-5"},
    {title: __t("order", true), path: "/order", icon: "ico-entity bg-black w-5 h-5"},
    {title: __t("media", true), path: "/media", icon: "ico-entity bg-black w-5 h-5"},
    {title: __t("setting", true), path: "/setting", icon: "ico-entity bg-black w-5 h-5"},
]

const MINISHOP_ROUTES: Array<{title: string, path: string, icon: string}> = [
    {title: __t("dashboard"), path: "/", icon: "ico-dashboard bg-black w-5 h-5"},
    {title: __t("category type", true), path: "/category-type", icon: "ico-entity bg-black w-5 h-5"},
    {title: __t("category", true), path: "/category", icon: "ico-entity bg-black w-5 h-5"},
    {title: __t("banner", true), path: "/banner", icon: "ico-entity bg-black w-5 h-5"},
    {title: __t("menu", true), path: "/menu", icon: "ico-entity bg-black w-5 h-5"},
    {title: __t("product", true), path: "/product", icon: "ico-entity bg-black w-5 h-5"},
    {title: __t("page", true), path: "/page", icon: "ico-entity bg-black w-5 h-5"},
    {title: __t("order", true), path: "/order", icon: "ico-entity bg-black w-5 h-5"},
    {title: __t("media", true), path: "/media", icon: "ico-entity bg-black w-5 h-5"},
    {title: __t("setting", true), path: "/setting", icon: "ico-entity bg-black w-5 h-5"},
]

const BLOGGER_ROUTES: Array<{title: string, path: string, icon: string}> = [
    {title: __t("dashboard"), path: "/", icon: "ico-dashboard bg-black w-5 h-5"},
    {title: __t("category type", true), path: "/category-type", icon: "ico-entity bg-black w-5 h-5"},
    {title: __t("category", true), path: "/category", icon: "ico-entity bg-black w-5 h-5"},
    {title: __t("banner", true), path: "/banner", icon: "ico-entity bg-black w-5 h-5"},
    {title: __t("menu", true), path: "/menu", icon: "ico-entity bg-black w-5 h-5"},
    {title: __t("page", true), path: "/page", icon: "ico-entity bg-black w-5 h-5"},
    {title: __t("blog", true), path: "/blog", icon: "ico-entity bg-black w-5 h-5"},
    {title: __t("media", true), path: "/media", icon: "ico-entity bg-black w-5 h-5"},
    {title: __t("setting", true), path: "/setting", icon: "ico-entity bg-black w-5 h-5"},
]

const getRoutes = (type: BusinessType): Array<{title: string, path: string, icon: string}> => {
    switch(type){
        case BusinessType.MINISHOP: 
            return MINISHOP_ROUTES;
        case BusinessType.BLOGGER:
            return BLOGGER_ROUTES;
        default: 
            return ROUTES; 
    }
}

export default function Layout(props: LayoutProp){
    const params = useParams()
    const [theme] = useContext(ThemeContext)
    useEffect(() => {
        if(!params?.id){
            theme.setFormButton(null)
        }
    }, [])
    const [lang, setLang] = useState(window.localStorage.getItem("language") || "en")
    const type = JSON.parse(window.localStorage.getItem("profile") || "{}")["tenant"]["type"];
    return (
        <div className={`${["ur", "ar", "fr"].includes(lang) ? "rtl" : "ltr"} flex`}>
            {/* <SideNav routes={getRoutes(type)} /> */}
            <div className="flex flex-col gap-4 w-full">
                <Top routes={getRoutes(type)} />
                <main className="container mx-auto px-5">
                    {props.children}
                </main>
            </div>
        </div>
    )
}