import gql from 'graphql-tag'

export const FETCH_PACKAGES = gql`
query FETCH_PACKAGES ($query: QueryParams) {
  GetPackages(query: $query) {
    _id
    title 
    tenure
    amount
    isUser
  }
}`

export const FETCH_ONE_PACKAGE = gql`
query FETCH_ONE_PACKAGE ($id: String!){
  GetOnePackage(id: $id){
    _id
    title 
    tenure
    amount
    isUser
  }
}`

export const CREATE_PACKAGE = gql`
mutation CREATE_PACKAGE ($packageInput: packageInput!){
  CreatePackage(packageInput: $packageInput){
    _id
  }
}`


export const UPDATE_PACKAGE = gql`
mutation UPDATE_PACKAGE ($id: String!,$packageInput: packageInput!){
  UpdatePackage(id: $id, packageInput: $packageInput){
    _id
  }
}
`

export const DELETE_PACKAGE = gql`
mutation DELETE_PACKAGE ($id: String!){
  DeletePackage(id: $id){
    _id
  }
}
`