import { Link } from "react-router-dom";
import Datatable, { Datasource } from "../../components/container/DataTable";
import Layout from "../../layouts";
import { DELETE_ORDER, FETCH_ORDERS } from "../../graphql";
import RemoveDialog from "../../components/common/RemoveDialog";
import moment from "moment";
import { __t } from "../../library/textHelper";

const DATASOURCE: Datasource = {
    columns: [
        { title: __t("id"), field: "_id" },
        { title: "Order Number", field: "orderNumber" },
        { title: "customer", field: "customer", template: (row) => (<div>{row.customer?.title}</div>) },
        { title: __t("status"), field: "status" },
    ],
    title: (row) => row.customer?.title,
    subTitle: (row) => (<div className="flex flex-col"><span>{row.orderNumber}</span><span>{moment(row.checkIn).format("DD-MMM-YYYY")} - {moment(row.checkOut).format("DD-MMM-YYYY")}</span></div>),
    actions: [
        (row, key) => (<Link key={key} to={`${row._id}`}><i className="ico-pencil-square w-5 h-5 bg-black">&nbsp;</i></Link>),
        (row, key) => (<RemoveDialog key={key} id={row._id} reload={true} query={DELETE_ORDER} />),
    ]
}

export default function Order(){
    return (
        <Layout>
            <Datatable query={FETCH_ORDERS} dataKey="GetOrders" datasource={DATASOURCE} />
        </Layout>
    )
}