import gql from 'graphql-tag'

export const FETCH_ACCOUNTS = gql`
query FETCH_ACCOUNTS ($query: QueryParams) {
    GetAccounts(query: $query) {
    _id
    title
    type
    openingBalance
  }
}
`

export const FETCH_ONE_ACCOUNT = gql`
query FETCH_ONE_ACCOUNT ($id: String!){
  GetOneAccount(id: $id){
    _id
    title
    type
    openingBalance
  }
}
`

export const CREATE_ACCOUNT = gql`
mutation CREATE_ACCOUNT ($accountInput: accountInput!){
  CreateAccount(accountInput: $accountInput){
    _id
    title
    type
    openingBalance
  }
}
`

export const UPDATE_ACCOUNT = gql`
mutation UPDATE_ACCOUNT ($id: String!,$accountInput: accountInput!){
  UpdateAccount(id: $id, accountInput: $accountInput){
    _id
    title
    type
    openingBalance
  }
}
`

export const DELETE_ACCOUNT = gql`
mutation DELETE_ACCOUNT ($id: String!){
  DeleteAccount(id: $id){
    _id
  }
}
`